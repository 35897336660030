import "./news_component.scss";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["cards"];

  connect() {
    if (this.hasCardsTarget) {
      this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
      this.anchorController?.checkForMultipleLinktags(this.cardsTarget);
    }
  }
}
