const slugify = (name) => {
  switch (name) {
    case "Bercy":
      return "bercy";
    case "Bras Marie":
      return "marie";
    case "Alexandre III":
      return "alexandre";
    case "Bras de Grenelle":
      return "grenelle";
    default:
      return "";
  }
};

// Usage in chart options
export const options = {
  scales: {
    xAxes: [
      {
        offset: true,
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontFamily: "'Montserrat', sans-serif",
          padding: 10,
        },
        gridLines: {
          drawTicks: false,
          drawBorder: false,
          color: '#adb8ff',
          lineWidth: 1
        },
      },
    ],
  },
  legend: {
    display: true,
    position: "bottom",
    labels: {
      generateLabels: (chart) => {
        const datasets = chart.data.datasets;
        return datasets.map((dataset, i) => {
          const img = document.querySelector(`[data-name=icon_${i + 1}]`).querySelector("img");

          return {
            text: `  ${dataset.label}     `,
            pointStyle: img,
            fillStyle: dataset.borderColor,
            hidden: !chart.isDatasetVisible(i),
            lineCap: dataset.borderCapStyle,
            lineDash: dataset.borderDash,
            lineDashOffset: dataset.borderDashOffset,
            lineJoin: dataset.borderJoinStyle,
            lineWidth: dataset.borderWidth,
            strokeStyle: dataset.borderColor,
            datasetIndex: i,
            icon: dataset.label.toLowerCase().replace(" ", "_"),
          };
        });
      },
      usePointStyle: true,
    },
  },
  elements: {
    line: {
      tension: 0.4,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 0,
      right: 10,
      top: 0,
      bottom: 0,
    },
  },
  tooltips: {
    intersect: false,
    backgroundColor: "rgba(255, 255, 255, 1)",
    titleFontColor: "rgba(85, 85, 85, 1)",
    titleFontFamily: "'Montserrat', sans-serif",
    bodyFontFamily: "'Montserrat', sans-serif",
    bodyFontColor: "rgba(85, 85, 85, 1)",
    displayColors: false,
    borderColor: "rgba(235, 235, 235, 1)",
    borderWidth: 0,
    enabled: false,
    cornerRadius: 0,
    mode: "index",
    axis: "x",
    position: "nearest",
    custom: customTooltip,
  },
};

function customTooltip(tooltipModel) {  
  // Tooltip Element
  let tooltipEl = document.querySelector("#chartjs-tooltip");

  // Create element on first render
  const template = document.querySelector("#blocks--wq-tooltip-tpl");
  if (template.dataset.tooltip === "false") return;

  if (!tooltipEl && template) {
    tooltipEl = template.content.cloneNode(true).firstElementChild;
    tooltipEl.id = "chartjs-tooltip";
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltipModel.body) {
    const dataLines = tooltipModel.body.map((data) => {
      const [name, value] = data.lines[0].split(": ");
      return {
        name: name.trim(),
        value: Number(value.trim()),
      };
    });

    let title = tooltipModel.title[0];

    // Replace TOOLTIP_TITLE and TOOLTIP_SUBTITLE
    tooltipEl.querySelector(".blocks--wq-tooltip-title").textContent = title;

    const dataContainer = tooltipEl.querySelector(".blocks--wq-tooltip-data");
    dataContainer.innerHTML = ""; // Clear previous content

    dataLines.forEach((line) => {
      const cssClass = slugify(line.name);

      const nameSpan = document.createElement("span");
      nameSpan.classList.add("blocks--wq-tooltip-name", `item-${cssClass}`);
      nameSpan.textContent = `${line.name} :`;

      const valueSpan = document.createElement("span");
      valueSpan.classList.add("blocks--wq-tooltip-value");
      valueSpan.textContent = line.value;

      dataContainer.appendChild(nameSpan);
      dataContainer.appendChild(valueSpan);
    });
  }

  const position = this._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  const halfWidth = position.width / 2;

  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = "absolute";

  if (tooltipModel.caretX > halfWidth) {    
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.clientWidth + "px";
  } else {
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
  }

  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
  tooltipEl.style.pointerEvents = "none";
}
