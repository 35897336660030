import { Controller as BaseController } from "stimulus";
import "./iframe_component.scss";

export class Controller extends BaseController {
  static targets = ["encodedContent", "cookies", "cookiesCheckbox"];

  // LIFECYCLE

  // connect() {}

  // ACTIONS

  displayIframe(event) {
    if (this.hasCookiesCheckboxTarget && this.cookiesCheckboxTarget.checked) {
      this.cookiesController = this.application.getControllerForElementAndIdentifier(document.body, "cookies");
      this.cookiesController.accept();
      return;
    }

    this.renderIframe(event);
  }

  renderIframe() {
    if (!this.hasEncodedContentTarget) {
      return;
    }

    this.encodedContentTarget.parentNode.replaceChild(this.encodedContentTarget.content, this.encodedContentTarget);

    if (this.hasCookiesTarget) {
      this.element.classList.remove("has-cookies-message");
      this.element.removeChild(this.cookiesTarget);
    }

    const iframe = this.element.querySelector("iframe");
    const originalHeight = iframe.getAttribute("data-original-height");

    if (this.element.classList.contains("is-video") && originalHeight) {
      this._custom_ratio(iframe, originalHeight);
    }
  }

  // PRIVATE

  _custom_ratio(iframe, originalHeight) {
    const ratio = ((parseInt(originalHeight) / 800) * 100).toFixed(2);
    this.element.setAttribute(
      "style",
      `width: 100%; height: 100%; border: none; margin: 0; overflow: hidden; padding-top: calc(${ratio}% + 20px);`
    );
  }
}
