import { Controller } from "stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["filter"];

  connect() {
    const tocElem = document.querySelector(".toc");
    this.tocController = this.application.getControllerForElementAndIdentifier(tocElem, "toc");

    this.activeFilters = this._getFiltersFromHash();
    this.filteredBlocks = [];

    this._getBlockFiltersAssociations().then(() => {
      this._initializeFilters();
      this._updateTocAndBlocks();
    });
  }

  filterContent(event) {
    const optionId = event.target.value;
    const filterKey = event.target.closest(".block-filter-row").dataset.filterKey;

    if ((event.target.type === "checkbox" && !event.target.checked) || optionId === "all") {
      delete this.activeFilters[filterKey];
      this._removeFilterFromHash(filterKey);
    } else {
      this.activeFilters[filterKey] = optionId;
      this._updateHashWithFilters();
    }

    this._updateTocAndBlocks();
  }

  clearFilters() {
    window.history.replaceState(null, null, `${window.location.pathname}`);
    this.activeFilters = {};

    this._clearSelectedFilters();
    this._updateTocAndBlocks();
  }

  _initializeFilters() {
    this.filterTargets.forEach((filterElement) => {
      const filterKey = filterElement.closest(".block-filter-row").dataset.filterKey;
      const filterValue = this.activeFilters[filterKey];

      if (filterElement.dataset.type === "selector" && filterValue) {
        filterElement.querySelector("select").value = filterValue;
      } else if (filterElement.dataset.type === "checkbox" && filterValue) {
        const checkbox = filterElement.querySelector("input[type=checkbox]");

        if (checkbox.value === filterValue) {
          checkbox.checked = true;
        }
      }
    });
  }

  _intersect(arrays) {
    if (arrays.length === 0) return [];
    return arrays.reduce((acc, array) => acc.filter((item) => array.includes(item)));
  }

  _clearSelectedFilters() {
    if (!this.hasFilterTarget) return;

    this.filterTargets.forEach((filter) => {
      if (filter.dataset.type === "selector") {
        filter.querySelector("select").selectedIndex = 0;
      } else if (filter.dataset.type === "checkbox") {
        filter.querySelector("input[type=checkbox]").checked = false;
      }
    });
  }

  _updateTocAndBlocks() {
    this._updateBlocksDisplay();

    this.tocController.initTitles();
    this.tocController.updateTocHeight();
  }

  _updateBlocksDisplay() {
    let arraysToIntersect = [];
    Object.keys(this.activeFilters).forEach((filterKey) => {
      const optionId = this.activeFilters[filterKey];
      if (this.filters[optionId]) {
        arraysToIntersect.push(this.filters[optionId]);
      }
    });

    const commonElements = arraysToIntersect.length > 0 ? this._intersect(arraysToIntersect) : [];
    const allVisible = Object.keys(this.activeFilters).length === 0;
    const blocksContent = document.querySelector(".blocks");
    const tocContent = document.querySelector(".toc-nav");

    blocksContent.querySelectorAll(".block").forEach((block) => {
      if (allVisible) {
        block.classList.remove("d-none");
      } else {
        const formattedId = this._formattedId(block);
        const isNested = block.classList.contains("block-nested");
        const isShown = commonElements.includes(formattedId);
        block.classList.toggle("d-none", !isShown);

        if (isNested) {
          block.closest(".block-group").classList.remove("d-none", !isShown);
        }
      }
    });

    tocContent.querySelectorAll(".toc-item").forEach((toc) => {
      if (allVisible) {
        toc.classList.remove("d-none");
      } else {
        const formattedId = this._formattedId(toc);
        toc.classList.toggle("d-none", !commonElements.includes(formattedId));
      }
    });
  }

  _formattedId(item) {
    return item.id.replace(/-/g, "_");
  }

  _getBlockFiltersAssociations() {
    const pageId = this.data.get("pageId");

    return axios
      .get(`/pages/${pageId}/filters`)
      .then((response) => {
        this.filters = response.data;
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  _getFiltersFromHash() {
    const hashParams = new URLSearchParams(window.location.hash.slice(1)); // Get the hash part after #
    let filters = {};
    const filterPattern = /^filter-\w+-\d+$/;

    for (let [key, value] of hashParams.entries()) {
      if (filterPattern.test(key)) {
        const filterKey = key.replace(/-/g, "_");
        filters[filterKey] = value;
      }
    }

    return filters;
  }

  _updateHashWithFilters() {
    const hashParams = new URLSearchParams(window.location.hash.slice(1));
    Object.keys(this.activeFilters).forEach((key) => {
      const hashKey = key.replace(/_/g, "-");
      hashParams.set(hashKey, this.activeFilters[key]);
    });
    this._replaceHash(hashParams);
  }

  _removeFilterFromHash(filterKey) {
    const hashParams = new URLSearchParams(window.location.hash.slice(1));
    const hashKey = filterKey.replace(/_/g, "-");
    hashParams.delete(hashKey);
    this._replaceHash(hashParams);
  }

  _replaceHash(hashParams) {
    const newUrl = `${window.location.pathname}#${hashParams.toString()}`;
    window.history.replaceState(null, null, newUrl);
  }
}
