import { connectRange } from "instantsearch.js/es/connectors";
import moment from "moment";

const calendarRange = (options, isFirstRendering) => {
  const { refine, widgetParams } = options;
  const { container } = widgetParams;
  const currentRefinement = options.instantSearchInstance.helper.state.numericRefinements.date;

  if (!isFirstRendering) return;

  const fragment = document.createRange().createContextualFragment(`
    <fieldset>
    <legend class="ais-DateSelector-label-title">${widgetParams.title || "Date(s)"}</legend>
    <label class="qfap-datepicker-display-text" for="search-filters-widget-date-range-start-date">Du</label>
    <input type="date" id="search-filters-widget-date-range-start-date" class="qfap-datepicker-display mb-1" aria-label="Date de début" />
    <label class="qfap-datepicker-display-text" for="search-filters-widget-date-range-end-date">Au</label>
    <input type="date" id="search-filters-widget-date-range-end-date" class="qfap-datepicker-display" aria-label="Date de fin" />
    </fieldset>
  `);
  container.appendChild(fragment);

  const startDateInput = document.querySelector("#search-filters-widget-date-range-start-date");
  const endDateInput = document.querySelector("#search-filters-widget-date-range-end-date");

  if (currentRefinement[">="]) {
    const startValue = moment.unix(currentRefinement[">="]).format("YYYY-MM-DD");
    const endValue = moment.unix(currentRefinement["<="]).format("YYYY-MM-DD");
    startDateInput.value = startValue;

    if (startValue !== endValue) {
      endDateInput.value = endValue;
    }
  }

  startDateInput.addEventListener("input", () => {
    let start = startDateInput.value === "" ? "" : moment(startDateInput.value).format("X");
    let end = endDateInput.value === "" ? start : moment(endDateInput.value).format("X");
    if (start === "") {
      start = end;
    }
    if (start > end) {
      endDateInput.value = startDateInput.value;
      end = start;
    }
    refine([start, end]);
  });

  endDateInput.addEventListener("input", () => {
    let end = endDateInput.value === "" ? "" : moment(endDateInput.value).format("X");
    let start = startDateInput.value === "" ? end : moment(startDateInput.value).format("X");
    if (end < start || end === start) {
      if (end === "") {
        end = start;
      } else {
        startDateInput.value = endDateInput.value;
        start = end;
      }
    }
    refine([start, end]);
  });
};

export default connectRange(calendarRange);
