export default (items) => {
  let number = 0;
  let itemNumbers = {};

  return items.map((item) => {
    if (!item._geoloc || !item._geoloc?.lat || !item._geoloc?.lng) return item;

    let extras = {};
    const uniqueKey = `${item._geoloc.lat},${item._geoloc.lng}`;

    if (uniqueKey in itemNumbers) {
      extras = { number: itemNumbers[uniqueKey] };
    } else {
      number += 1;
      itemNumbers[uniqueKey] = number;
      extras = { number, itemId: `hit-${number}` };
    }

    return { ...item, ...extras };
  });
};
