/* global gon */
// import { /*refinementList,*/ toggleRefinement /*currentRefinements*/ } from "instantsearch.js/es/widgets";
import { districtNameSortBy, localize } from "../utils";
// import icon from "../../icon/helper";
import dateRange from "./widgets/date_range";
import refinementDropdown from "./widgets/refinement_dropdown";
import tagDropdown from "./widgets/tag_dropdown";
import searchableDropdown from "./widgets/searchable_dropdown";
import refinementCheckBoxes from "./widgets/refinement_checkboxes";
import toggleRefinement from "./widgets/toggle_refinement";

// import toggleNumericRefinement from "./widgets/toggle_numeric_refinement";

// const defaultRefinementListItemTemplate = `
//   <label class="{{cssClasses.label}}">
//   <input type="checkbox"
//         class="{{cssClasses.checkbox}}"
//         value="{{value}}"
//         {{#isRefined}}checked{{/isRefined}} />
//   <span class="{{cssClasses.labelText}}">{{label}}</span>
//   </label>
// `;

// const electedsRefinementListItemTemplate = `
//   <label class="{{cssClasses.label}}">
//   <input type="checkbox"
//         class="{{cssClasses.checkbox}}"
//         value="{{value}}"
//         {{#isRefined}}checked{{/isRefined}} />
//   <span class="{{cssClasses.labelText}}">{{label}} <span class="{{cssClasses.count}}">({{#helpers.formatNumber}}{{count}}{{/helpers.formatNumber}})</span></span>
//   </label>`;

const sortAs = (arr) => (a, b) => {
  return arr.indexOf(a.name) - arr.indexOf(b.name);
};

const commonWidgets = {
  free: {
    widget: (container) =>
      toggleRefinement({
        attribute: "free",
        labelId: "free_free",
        templates: {
          labelText: localize("filters.free.label"),
        },
        container,
      }),
  },
  fullDistricts: {
    widget: (container) =>
      refinementDropdown({
        attribute: "full_districts",
        labelId: "fullDistricts_full_districts",
        limit: 25,
        sortBy: districtNameSortBy("Paris Centre", "Hors Paris"),
        title: localize("filters.district.title"),
        templates: {
          defaultOption: localize("filters.district.title"),
        },
        container,
      }),
  },
  accessibilityKeys: {
    widget: (container) =>
      refinementCheckBoxes({
        attribute: "accessibility",
        icon: true,
        sortBy: ["count:desc", "name:asc"],
        transformItems: (items) => {
          return items.map((item) => ({
            ...item,
            label: localize("filters.accessibility." + item.label),
          }));
        },
        // templates: {
        //   item: ({ cssClasses, isRefined, label, value }) => `
        //     <label class="${cssClasses.label}">
        //     <input type="checkbox"
        //           class="${cssClasses.checkbox}"
        //           value="${value}"
        //           ${isRefined ? "checked" : ""} />
        //     <span class="${cssClasses.labelText}">${icon("accessibility/" + value)} ${label}</span>
        //     </label>
        //   `,
        // },
        container,
      }),
  },
};

// const now = Math.floor(Date.now() / 1000);
const weekDays = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"];
// const weekDays = gon.search.locales.filters.slot.week_days;
const timeRanges = ["matin", "midi", "après-midi", "soir", "nuit"];
// const timeRanges = gon.search.locales.filters.slot.time_ranges;

export default {
  // We added the common.free widget to everthing and info index to fix nbhits
  // Otherwise, we noticed a bug:
  // On a given query,
  // the nbhits of the everything and info indexes could change (nbhits caped to ~ 1000) when we switched to the other indexes.
  // One of the likely causes of this bug would be that we need a widget that applies a refine to have the exact nbhits
  // We hide everything & info widget in search_controller.js -> _toggleFilterContainer()
  everything: [commonWidgets.free],
  info: [commonWidgets.free],
  sortir: [
    commonWidgets.fullDistricts,
    {
      widget: (container) =>
        refinementDropdown({
          attribute: "universe",
          labelId: "sortir_universe",
          limit: 50,
          sortBy: ["name:asc"],
          title: localize("filters.universe.title"),
          templates: {
            defaultOption: localize("filters.universe.title"),
          },
          container,
        }),
    },
    commonWidgets.free,
    {
      widget: (container) =>
        tagDropdown({
          attribute: "tags.name",
          limit: 50,
          operator: "and",
          sortBy: ["name:asc"],
          templates: {
            defaultOption: " ",
          },
          container,
        }),
    },
    {
      widget: (container) =>
        dateRange({
          title: "Date(s)",
          attribute: "date",
          incompatibleAttribute: "dateEnd",
          isRange: false,
          container,
        }),
    },
    {
      widget: (container) =>
        searchableDropdown({
          attribute: "searchable_place",
          limit: 20,
          sortBy: ["name:asc"],
          container,
        }),
    },
    // {
    //   widget: (container) =>
    //     dateRange({
    //       attribute: "sorting_date",
    //       incompatibleAttribute: "dateEnd",
    //       isRange: true,
    //       container,
    //     }),
    // },

    // {
    //   widget: (container) =>
    //     toggleNumericRefinement({
    //       attribute: "dateEnd",
    //       incompatibleAttribute: "sorting_date",
    //       on: ["<=", now],
    //       off: [">", now],
    //       templates: {
    //         labelText: "Évènements terminés",
    //       },
    //       container,
    //     }),
    // },
    {
      title: localize("filters.audience.title"),
    },
    {
      modifiers: "is-grouped",
      widget: (container) =>
        toggleRefinement({
          attribute: "toddlers",
          templates: {
            labelText: localize("filters.audience.with_age.toddlers"),
          },
          container,
        }),
    },
    {
      modifiers: "is-grouped",
      widget: (container) =>
        toggleRefinement({
          attribute: "children",
          labelId: "sortir_children",
          templates: {
            labelText: localize("filters.audience.with_age.children"),
          },
          container,
        }),
    },
    {
      modifiers: "is-grouped",
      widget: (container) =>
        toggleRefinement({
          attribute: "teens",
          labelId: "sortir_teens",
          templates: {
            labelText: localize("filters.audience.with_age.teens"),
          },
          container,
        }),
    },
    {
      modifiers: "is-grouped",
      widget: (container) =>
        toggleRefinement({
          attribute: "adults",
          labelId: "sortir_adults",
          templates: {
            labelText: localize("filters.audience.adults"),
          },
          container,
        }),
    },
    commonWidgets.accessibilityKeys,
  ],
  activities: [
    commonWidgets.fullDistricts,
    {
      widget: (container) =>
        refinementDropdown({
          attribute: "discipline",
          labelId: "activities_discipline",
          limit: 50,
          sortBy: ["name:asc"],
          title: localize("filters.discipline.title"),
          templates: {
            defaultOption: localize("filters.discipline.title"),
          },
          container,
        }),
    },
    {
      ...commonWidgets.free,
      ...{ modifiers: "is-grouped" },
    },
    // TODO: uncomment this when the ActivitySerializer in QFAP includes `last_inscription`
    // {
    //   modifiers: "is-grouped",
    //   widget: container =>
    //     toggleNumericRefinement({
    //       attribute: "last_inscription",
    //       on: [">", now],
    //       off: ["<=", now],
    //       templates: {
    //         labelText: "Inscriptions en cours"
    //       },
    //       container
    //     })
    // },
    {
      title: localize("filters.slot.title"),
    },
    {
      widget: (container) =>
        refinementCheckBoxes({
          attribute: "weekday",
          sortBy: sortAs(weekDays),
          transformItems: (items) => {
            return items.map((item) => ({
              ...item,
              label: item.label.toLowerCase(),
            }));
          },
          cssClasses: {
            list: "has-2-cols is-capitalized",
          },
          container,
        }),
    },
    {
      widget: (container) =>
        refinementCheckBoxes({
          attribute: "time_range",
          sortBy: sortAs(timeRanges),
          cssClasses: {
            list: "has-2-cols is-capitalized",
          },
          container,
        }),
    },
    {
      title: localize("filters.audience.title"),
    },
    {
      modifiers: "is-grouped",
      widget: (container) =>
        toggleRefinement({
          attribute: "children",
          labelId: "activities_children",
          templates: {
            labelText: localize("filters.audience.children"),
          },
          container,
        }),
    },
    {
      modifiers: "is-grouped",
      widget: (container) =>
        toggleRefinement({
          attribute: "teens",
          labelId: "activities_teens",
          templates: {
            labelText: localize("filters.audience.teens"),
          },
          container,
        }),
    },
    {
      modifiers: "is-grouped",
      widget: (container) =>
        toggleRefinement({
          attribute: "adults",
          labelId: "activities_adults",
          templates: {
            labelText: localize("filters.audience.adults"),
          },
          container,
        }),
    },
    commonWidgets.accessibilityKeys,
  ],
  places: [
    commonWidgets.fullDistricts,
    {
      widget: (container) =>
        refinementDropdown({
          attribute: "category_names",
          limit: 80,
          sortBy: ["name:asc"],
          title: localize("filters.place_category.title"),
          labelId: "places_category_names",
          templates: {
            defaultOption: localize("filters.place_category.title"),
          },
          container,
        }),
    },
    {
      widget: (container) =>
        toggleRefinement({
          attribute: "is_open",
          labelId: "places_is_open",
          templates: {
            labelText: localize("filters.open.label"),
          },
          container,
        }),
    },
    {
      title: localize("filters.audience.title"),
    },
    commonWidgets.accessibilityKeys,
  ],
  electeds: [
    {
      title: "Groupe politique",
      widget: (container) =>
        refinementCheckBoxes({
          attribute: "political_group",
          count: true,
          limit: 20,
          sortBy: ["count:desc", "name:asc"],
          cssClasses: {
            list: "has-counts",
          },
          container,
        }),
    },
    {
      title: "Arrondissement",
      skip: () => gon.mairie.district,
      widget: (container) =>
        refinementCheckBoxes({
          attribute: "district_name",
          count: true,
          limit: 20,
          sortBy: districtNameSortBy("Paris Centre"),
          cssClasses: {
            list: "has-counts",
          },
          container,
        }),
    },
  ],
};
