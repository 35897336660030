import "./contribute_component.scss";

import { Controller as BaseController } from "stimulus";
import { useClickOutside } from "stimulus-use";
import "wicg-inert";

export class Controller extends BaseController {
  static targets = ["openButton", "closeButton", "panel", "title"];

  initialize() {
    this.keydownHandler = this.keydown.bind(this);
    this.closeHandler = (e) => {
      if (!this.opened || e.detail.originalEvent.target == this.openButtonTarget) return;
      this.close();
    };
  }

  connect() {
    this.opened = false;
    this.panelTarget.inert = true;
    useClickOutside(this, {
      element: this.panelTarget,
    });
    this.titleTarget.setAttribute("tabindex", -1);
  }

  open() {
    if (this.opened) return;
    this.opened = true;
    this.panelTarget.inert = false;
    this.element.classList.add("is-open");
    document.body.classList.add("has-disabled-scroll");
    this.enableKeyboard();
    this.titleTarget.focus();
    this.element.addEventListener("qfap--modals--contribute:click:outside", this.closeHandler);
  }

  close() {
    if (!this.opened) return;
    this.opened = false;
    this.panelTarget.inert = true;
    this.element.classList.remove("is-open");
    document.body.classList.remove("has-disabled-scroll");
    this.openButtonTarget.classList.remove("qfap--modals--contribute-button-animated");
    this.disableKeyboard();
    this.openButtonTarget.focus();
    this.element.removeEventListener("qfap--modals--contribute:click:outside", this.closeHandler);
  }

  toggle() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  enableKeyboard() {
    window.addEventListener("keydown", this.keydownHandler);
  }

  disableKeyboard() {
    window.removeEventListener("keydown", this.keydownHandler);
  }

  keydown(e) {
    switch (e.code) {
      case "Tab":
        if (e.target == this.firstFocusableElement && e.shiftKey) {
          this.lastFocusableElement.focus();
          e.preventDefault();
          e.stopPropagation();
        } else if (e.target == this.lastFocusableElement && !e.shiftKey) {
          this.firstFocusableElement.focus();
          e.preventDefault();
          e.stopPropagation();
        }
        break;
      case "Escape":
        this.close();
        break;
    }
  }

  get focusableElements() {
    return this.panelTarget.querySelectorAll(
      "a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled])"
    );
  }

  get firstFocusableElement() {
    return this.focusableElements[0];
  }

  get lastFocusableElement() {
    return this.focusableElements[this.focusableElements.length - 1];
  }
}
