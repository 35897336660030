import "./filters_component.scss";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["item", "showMoreButton"];

  initialize() {
    this.mediaQueryList = window.matchMedia("(max-width: 799px)");
  }

  connect() {
    this.currentItem = this.itemTargets.find((item) => item.classList.contains("is-current"));

    if (this.isSmallAndMedium && this.itemTargets.length > 5) {
      this.connectShowMore();
    }
  }

  connectShowMore() {
    this.itemTargets.forEach((item, index) => {
      if (index >= 5) {
        item.classList.add("is-hidden");
      }
    });
    this.element.insertAdjacentHTML(
      "beforeend",
      `<button class="qfap--filters-show-more" data-action="qfap--filters#showMore" data-target="qfap--filters.showMoreButton">Voir plus de tags</button>`
    );
  }

  toggleItem(e) {
    e.preventDefault();

    if (this.currentItem == e.target) return;

    this.currentItem = e.target;

    this.itemTargets.forEach((item) => {
      item.classList.toggle("is-current", item == this.currentItem);
    });
  }

  showMore(e) {
    e.preventDefault();

    this.itemTargets.forEach((item) => {
      item.classList.remove("is-hidden");
    });

    this.showMoreButtonTarget.remove();
  }

  get isSmallAndMedium() {
    return this.mediaQueryList.matches;
  }
}
