import "./attendances_component.scss";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["current"];

  connect() {
    this._isExpanded = this.currentTarget.classList.contains('expanded');
  }

  toggle(e) {
    e.preventDefault();
    this._isExpanded = !this._isExpanded;
    this.currentTarget.classList.toggle('expanded', this._isExpanded)
  }
}

