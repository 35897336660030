import { connectToggleRefinement } from 'instantsearch.js/es/connectors';

const renderToggleRefinement = (renderOptions, isFirstRender) => {
  const { value, refine, widgetParams } = renderOptions;
  const { container, templates, labelId } = widgetParams

  if (isFirstRender) {
    const fragment = document.createRange().createContextualFragment(`
    <div class="ais-ToggleRefinement">
      <input class="ais-ToggleRefinement-checkbox" type="checkbox" id="${labelId}" />
      <label class="ais-ToggleRefinement-label" for="${labelId}">${templates.labelText}</label>
    </div>
  `);

    const input = fragment.querySelector('input')
    input.addEventListener('change', event => {
      refine({ isRefined: !event.target.checked });
    });

    container.appendChild(fragment);
  }


  container.querySelector('input').checked = value.isRefined;
};

export default connectToggleRefinement(renderToggleRefinement)
