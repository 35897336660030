import "./header_component.scss";

import { useHotkeys } from "stimulus-use";
import hotkeys from "hotkeys-js";
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["content", "searchButton", "accessibilityButton", "nav", "navLink", "searchModal", "subnav"];
  initialize() {
    this.mediaQueryList = window.matchMedia("(max-width: 799px)");
    this.timeout = false;
    this.open = false;
    this.openedSubnav = null;
    this.leaveTimeout = null;
    this.searching = false;
  }

  connect() {
    this.initTemplates();
    this.initHover();
    this.initKeyboard();

    if (this.isSmallAndMedium && this.hasSearchModalTarget) {
      this.searchModalTarget.addEventListener("search-modal:input-focus", () => this.startSearching());
    }
  }

  initTemplates() {
    if (!this.templatesSrc) return;

    fetch(this.templatesSrc)
      .then((response) => response.text())
      .then((html) => {
        const templates = html.matchAll(/<template id="([^"]+)">(.+?)<\/template>/gs);

        for (const template of templates) {
          const destination = document.getElementById(template[1]);

          if (!destination) continue;

          destination.innerHTML = template[2];
        }
      });
  }

  initHover() {
    if (this.isSmallAndMedium) return;

    this.hover = true;
  }

  enterNavLink(e) {
    if (!this.hover) return;
    this.cancelTimeout();
    const enteredNavLink = e.target.closest('[data-target="header.navLink"]');
    this.openSubnav(enteredNavLink);
  }

  leaveNavLink(e) {
    if (!this.hover) return;
    const enteringSubnav = e.relatedTarget && !!e.relatedTarget.closest('[data-target="header.subnav"]');
    if (enteringSubnav) return;

    this.closeSubnav(false);
  }

  toggleNavLink(e) {
    if (this.isSmallAndMedium) return;
    const enteredNavLink = e.target.closest('[data-target="header.navLink"]');
    if (enteredNavLink == this.openedSubnav) {
      this.closeSubnav();
    } else {
      this.openSubnav(enteredNavLink);
    }
  }

  enterSubnav() {
    this.cancelTimeout();
  }

  leaveSubnav() {
    if (!this.hover) return;
    this.closeSubnavAfterTimeout();
  }

  initKeyboard() {
    if (this.isSmallAndMedium) return;

    hotkeys.setScope("header-subnav-closed");

    useHotkeys(this, {
      hotkeys: {
        // Esc
        // - If a subnav is open, close it and set focus on the nav item that controls that subnav.
        esc: {
          handler: this.closeSubnav,
          options: {
            scope: "header-subnav-open",
          },
        },
        // Space, Enter
        // - If focus is on a nav item, open the subnav.
        // - If the subnav is already open, close it.
        "space,enter": {
          handler: (e) => {
            if (e.target.getAttribute("data-target") == "header.navLink") {
              e.preventDefault();

              if (this.openedSubnav == e.target) {
                this.closeSubnav();
              } else {
                this.openSubnav(e.target);
                const $mainLink = this.currentSubnav.querySelector(".paris-header-subnav-main-link");
                if ($mainLink) {
                  $mainLink.classList.add("subnav-in-focus");
                }
              }
            }
          },
        },
        // Down arrow
        // - If focus is on a nav item, open the subnav and set focus on the first link in the subnav.
        // - If focus is on a link in a subnav (not the last one), set focus on the next link in the subnav.
        down: {
          handler: (e) => {
            if (e.target.getAttribute("data-target") == "header.navLink") {
              e.preventDefault();

              if (this.openedSubnav == e.target) {
                this.currentSubnavLinks[0].focus();
              } else {
                this.openSubnav(e.target);
              }
            } else if (e.target.classList.contains("paris-header-subnav-link")) {
              e.preventDefault();

              const currentLinkIndex = Array.from(this.currentSubnavLinks).indexOf(e.target);

              const nextLink = this.currentSubnavLinks[currentLinkIndex + 1];
              if (nextLink) nextLink.focus();
            }
          },
        },
        // Up arrow
        // - If focus is on a nav item and the subnav it controls is open, close the subnav and set focus on the nav item.
        // - If focus is on a link in a subnav (not the first one), set focus on the previous link in the subnav.
        up: {
          handler: (e) => {
            if (e.target.getAttribute("data-target") == "header.navLink") {
              e.preventDefault();
              this.closeSubnav();
            } else if (e.target.classList.contains("paris-header-subnav-link")) {
              e.preventDefault();

              const currentLinkIndex = Array.from(this.currentSubnavLinks).indexOf(e.target);

              const previousLink = this.currentSubnavLinks[currentLinkIndex - 1];
              if (previousLink) previousLink.focus();
            }
          },
          options: {
            scope: "header-subnav-open",
          },
        },
        // Left arrow
        // - If focus is on a nav item (not the first one), set focus on the previous nav item.
        left: {
          handler: (e) => {
            if (e.target.classList.contains("paris-header-nav-link")) {
              e.preventDefault();

              const links = this.element.querySelectorAll(".paris-header-nav-link");
              const currentLinkIndex = Array.from(links).indexOf(e.target);

              const previousLink = links[currentLinkIndex - 1];
              if (previousLink) previousLink.focus();
            }
          },
          options: {
            scope: "header-subnav-closed",
          },
        },
        // Right arrow
        // - If focus is on a nav item (not the last one), set focus on the next nav item.
        right: {
          handler: (e) => {
            if (e.target.classList.contains("paris-header-nav-link")) {
              e.preventDefault();

              const links = this.element.querySelectorAll(".paris-header-nav-link");
              const currentLinkIndex = Array.from(links).indexOf(e.target);

              const nextLink = links[currentLinkIndex + 1];
              if (nextLink) nextLink.focus();
            }
          },
          options: {
            scope: "header-subnav-closed",
          },
        },
      },
    });
  }

  openSubnav(navLink) {
    if (this.openedSubnav == navLink) return;
    if (this.openedSubnav) this.closeSubnav(false);

    this.openedSubnav = navLink;
    navLink.setAttribute("aria-expanded", "true");
    hotkeys.setScope("header-subnav-open");
    this.currentSubnav.inert = false;
  }

  closeSubnavAfterTimeout() {
    this.leaveTimeout = setTimeout(() => this.closeSubnav(false), 350);
  }

  cancelTimeout() {
    if (!this.leaveTimeout) return;

    clearTimeout(this.leaveTimeout);
    this.leaveTimeout = null;
  }

  closeSubnav(giveFocus = true) {
    if (!this.openedSubnav) return;
    this.openedSubnav.setAttribute("aria-expanded", "false");
    if (giveFocus) this.openedSubnav.focus();
    hotkeys.setScope("header-subnav-closed");
    // Mobile accessibility
    if (this.isSmallAndMedium && this.openedSubnav === this.accessibilityButtonTarget) {
      this.accessibilityButtonTargets[1].setAttribute("aria-expanded", false);
    }
    const $mainLink = this.currentSubnav.querySelector(".paris-header-subnav-main-link");
    if ($mainLink) {
      $mainLink.classList.remove("subnav-in-focus");
    }
    this.currentSubnav.inert = true;
    this.openedSubnav = null;
  }

  goToSubnav(e) {
    if (this.isLarge) return;
    e.preventDefault();
    if (this.openedSubnav) return;

    const navLink = e.target.closest('[data-target="header.navLink"]');
    this.openSubnav(navLink);
    this.refreshClasses();
  }

  backFromSubnav(e) {
    e.preventDefault();
    if (!this.openedSubnav) return;

    this.closeSubnav(false);
    this.refreshClasses();
  }

  toggleMenu(e) {
    if (e) e.preventDefault();
    this.open = !this.open;
    this.contentTarget.scrollTop = 0;
    this.closeSubnav(false);
    this.emptySearchBox();

    const searchModalController = this.searchModalController;
    if (searchModalController && searchModalController.isSmallAndMedium && !searchModalController.initialized) {
        searchModalController.initModal();
    }
    this.refreshClasses();
  }

  closeMenu() {
    if (!this.open) return;

    this.open = false;
    this.searching = false;

    this.refreshClasses();
  }

  stopSearching(e) {
    if (e) e.preventDefault();

    this.searching = false;
    this.emptySearchBox();
    this.refreshClasses();
  }

  startSearching() {
    if (this.searching) return;

    this.searching = true;
    this.refreshClasses();
  }

  emptySearchBox() {
    const searchBoxInput = this.element.querySelector(".ais-SearchBox-input");
    // Ensure searchBoxInput exists before trying to clear its value
    if (searchBoxInput) {
        searchBoxInput.value = "";
    }
  }

  toggleSearch(e) {
    if (e) e.preventDefault();
    if (this.isSmallAndMedium) return;

    this.toggleSearchButton();
    if (this.searchModalController) this.searchModalController.toggle(null, true);
  }

  toggleAccessibility(e) {
    if (e) e.preventDefault();

    const navLink = this.accessibilityButtonTarget;
    if (this.openedSubnav == navLink) {
      this.closeSubnav();
      return;
    }

    // For mobile lets set aria-expanded on button and scrollTop 0
    if (this.isSmallAndMedium) {
      this.contentTarget.scrollTop = 0;
      e.target.setAttribute("aria-expanded", "true");
    }

    this.openSubnav(navLink);
    this.refreshClasses();
  }

  refreshClasses() {
    this.element.classList.toggle("has-mobile-menu-open", this.open);
    this.element.classList.toggle("is-searching", this.open && this.searching);
    this.element.classList.toggle("is-not-searching", this.open && !this.searching);
    this.element.classList.toggle("has-subnav-open", this.open && !this.searching && !!this.openedSubnav);
    document.body.classList.toggle("has-disabled-scroll", this.open);
  }

  toggleSearchButton() {
    this.open = !this.open;
    this.searchButtonTarget.classList.toggle("is-active", this.open);
  }

  get searchModalController() {
    if (!this.hasSearchModalTarget) return null;

    return this.application.getControllerForElementAndIdentifier(this.searchModalTarget, "search--search-modal");
  }

  get isSmallAndMedium() {
    return this.mediaQueryList.matches;
  }

  get isLarge() {
    return !this.isSmallAndMedium;
  }

  get templatesSrc() {
    return this.element.dataset.templatesSrc;
  }

  get currentSubnav() {
    return this.openedSubnav.nextElementSibling;
  }

  get currentSubnavLinks() {
    return this.currentSubnav.querySelectorAll(".paris-header-subnav-link");
  }
}
