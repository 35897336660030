import "./place_component.scss";
import { Controller as BaseController } from "stimulus";

import L from "leaflet";

import MARKER from "./images/default.svg";


const DEFAULT_ICON = {
  iconSize: [21, 32], 
  iconAnchor: [10, 32]
};

const ICON = L.icon({
  iconUrl: MARKER,
  ...DEFAULT_ICON
});


const DEFAULT_ZOOM = 14;
const DEFAULT_LAYER_ATTRIBUTION = 'Map data &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
const TILE_LAYER = "https://cdn.paris.fr/leaflet/paris/{z}/{x}/{y}.png";
const TILE_LAYER_MIN_ZOOM = 11;

// Override leaflet default icon marker
// Ref: https://leafletjs.com/reference-1.5.0.html#icon-default
export class Controller extends BaseController {
  static targets = ["map"];

  initialize() {
    this._map = null;
    this._options = {
      zoom: DEFAULT_ZOOM,
      scrollWheelZoom: false,
      zoomControl: true
    };
  }

  connect() {    
    this.initMap();
  }

  initMap() {
    if (!this.hasMapTarget) {
      return;
    }

    this._map = null;
    
    // Read Attributes
    this._latLng = L.latLng(this.element.dataset.placeLatlng.split(","));
    this._status = this.element.dataset.placeStatus || null;

    L.Marker.prototype.options.icon = ICON;

    // Create Map
    this._map = L.map(this.mapTarget, this._options);

    // Assign TileLayer
    L.tileLayer(TILE_LAYER, {
      minZoom: TILE_LAYER_MIN_ZOOM,
      attribution: DEFAULT_LAYER_ATTRIBUTION
    }).addTo(this._map);

    // Set View
    this._map.setView(this._latLng);

    // Add Marker
    L.marker(this._latLng).addTo(this._map);
  }
}
