import "./cta_component.scss";
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = [
    "contact",
    "emailInput",
    "errorMessage",
    "hpts",
    "newsletterForm",
    "submit",
    "subscribeBtn",
    "subscribeBtnCheck",
    "subscribeBtnLoader",
    "subscribeBtnText",
    "successMessage",
  ];

  initialize() {}

  connect() {}

  onPostSubmit(e) {
    e.preventDefault();
    this.nlid = this.newsletterFormTarget.dataset.nlid;
    if (!this.subscribeBtnTarget.classList.contains("disabled") && this.emailInputTarget.value !== "") {
      this.toggleDisable(this.subscribeBtnTarget);
      this.toggleBtnContent(this.subscribeBtnTextTarget, this.subscribeBtnLoaderTarget);
      this.resetToInitialState();
      fetch("/csrf_meta.json", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.csrfToken = data.token;
          this.replaceTokenInDom(this.csrfToken);
          this._submitNewsletter();
        });
    }
  }

  // PRIVATE
  _submitNewsletter() {
    const _this = this;
    fetch(this.newsletterFormTarget.action, {
      method: "POST",
      body: JSON.stringify({
        newsletter: {
          email: this.emailInputTarget.value,
          hpts: this.hptsTarget.value,
          contact: this.contactTarget.value,
          nl_id: this.nlid || "",
        },
      }),
      credentials: "include",
      cache: "no-cache",
      dataType: "script",
      headers: {
        "X-CSRF-Token": this.csrfToken,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        if (response.ok) {
          response.json().then(function (data) {
            if (data.status === "success") {
              _this._subscriptionSuccessful(data.message);
            } else {
              _this._subscriptionError(data.message);
            }
          });
        } else {
          _this._subscriptionError();
        }
      })
      .catch(function (error) {
        console.log("Problème avec l'opérateur fetch : " + error.message);
        _this._subscriptionError(error.message);
      });
  }

  _subscriptionError(optionalMessage) {
    const message = optionalMessage || "Un problème de connexion a eu lieu";
    this.toggleBtnContent(this.subscribeBtnLoaderTarget, this.subscribeBtnTextTarget);
    this.emailInputTarget.style.borderColor = "#E7464B";
    let eMessage = this.errorMessageTarget;
    eMessage.role = "alert";
    eMessage.classList.remove("hidden");
    eMessage.innerHTML = "L'inscription a échoué : " + message;
    this.toggleDisable(this.subscribeBtnTarget);
  }

  _subscriptionSuccessful(optionalMessage) {
    const message = optionalMessage || "Merci ! Votre inscription a bien été enregistrée.";
    this.toggleBtnContent(this.subscribeBtnLoaderTarget, this.subscribeBtnCheckTarget);
    this.emailInputTarget.style.borderColor = "#257d1B";
    let sMessage = this.successMessageTarget;
    sMessage.role = "status";
    sMessage.classList.remove("hidden");
    sMessage.innerHTML = message;
  }

  toggleDisable(element) {
    element.disabled = !element.disabled;
  }

  toggleBtnContent(element1, element2) {
    element1.classList.toggle("hidden");
    element2.classList.toggle("hidden");
  }

  resetToInitialState() {
    this.emailInputTarget.style.borderColor = "rgba(0,0,0,0)";
    let eMessage = this.errorMessageTarget;
    if (!eMessage.classList.contains("hidden")) {
      eMessage.classList.add("hidden");
    }
  }

  replaceTokenInDom(token) {
    document.querySelectorAll('input[name="authenticity_token"]').forEach((e) => {
      e.value = token;
    });
  }
}
