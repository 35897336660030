/* global gon */

import "./open_closed_component.scss";

import { Controller as BaseController } from "stimulus";
import algoliasearch from "algoliasearch";

export class Controller extends BaseController {
  static targets = [];

  connect() {
    const id = parseInt(this.data.get("place-id"), 10);

    if (!id) {
      return;
    }

    const client = algoliasearch(gon.algolia.application_id, gon.algolia.api_key);
    const index = client.initIndex(gon.algolia.indexes.places.name);

    index
      .getObject("Service#" + id, {
        attributesToRetrieve: ["is_open", "open_details"],
      })
      .then((content) => this.updateStatus(content))
      .catch((error) => console.error(error));
  }

  updateStatus(content) {
    if (content.is_open === "unknown" || !content.open_details) {
      return;
    }

    this.element.classList.add("is-" + content.open_details.color);
    this.element.innerHTML = content.open_details.text;
  }
}
