export const setCookie = (name, value, days) => {
  const cookie = [name + "=" + encodeURIComponent(value)];

  days = Number(days);
  if (days) {
    cookie.push("max-age=" + days * 86400);
  }

  cookie.push("path=/");
  cookie.push("samesite=strict");

  document.cookie = cookie.join("; ");
};

export const getCookie = (name) => {
  const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
  const desiredCookie = cookies.find((cookie) => cookie.startsWith(name + "="));

  if (desiredCookie) {
    return decodeURIComponent(desiredCookie.substring(name.length + 1));
  }

  return null;
};
