import moment from "moment";
moment.locale("fr");
import "./newsletter_component.scss";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = [
    "subscribeBtn",
    "subscribeBtnText",
    "subscribeBtnLoader",
    "subscribeBtnCheck",
    "emailInput",
    "successMessage",
    "errorMessage",
    "newsletterForm",
    "hpts",
    "contact",
    "submit",
  ];

  onPostSubmit = (e) => {    
    e.preventDefault();
    this.nlId = this.newsletterFormTarget.dataset.nlId;

    if (!this.subscribeBtnTarget.classList.contains("disabled") && this.emailInputTarget.value !== "") {
      this.toggleDisable(this.subscribeBtnTarget);
      this.toggleBtnContent(this.subscribeBtnTextTarget, this.subscribeBtnLoaderTarget);
      this.resetToInitialState();

      fetch("/csrf_meta.json", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {          
          this.csrfToken = data.token;
          this.replaceTokenInDom(this.csrfToken);
          this._submitNewsletter();
        });
    }
  };

  // PRIVATE
  _submitNewsletter() {
    fetch(this.newsletterFormTarget.dataset.url, {
      method: "POST",
      body: JSON.stringify({
        newsletter: {
          email: this.emailInputTarget.value,
          hpts: this.hptsTarget.value,
          contact: this.contactTarget.value,
          nl_id: this.nlId || "",
        },
      }),
      credentials: "include",
      cache: "no-cache",
      dataType: "script",
      headers: {
        "X-CSRF-Token": this.csrfToken,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.status === "success") {
              this._subscriptionSuccessful(data.message);
            } else {
              this._subscriptionError(data.message);
            }
          });
        } else {
          this._subscriptionError();
        }
      })
      .catch(function (error) {
        console.log("problème avec l'opérateur fetch : " + error.message);
        this._subscriptionError(error.message);
      });
  }

  _subscriptionError(optionalMessage) {
    const message = optionalMessage || "un problème de connexion a eu lieu";
    this.toggleBtnContent(this.subscribeBtnLoaderTarget, this.subscribeBtnTextTarget);
    this.emailInputTarget.style.borderColor = "#E7464B";
    const eMessage = this.errorMessageTarget;
    eMessage.id = `${this.emailInputTarget.id}-email-error`;
    eMessage.role = "alert";
    eMessage.classList.remove("hidden");
    eMessage.innerText = "L'inscription a échoué : " + message;
    // Update ARIA described-by to link error
    this.emailInputTarget.setAttribute("aria-describedby", eMessage.id);
    this.toggleDisable(this.subscribeBtnTarget);
  }

  _subscriptionSuccessful(optionalMessage) {
    const message = optionalMessage || "Merci ! Votre inscription a bien été enregistrée.";
    this.toggleBtnContent(this.subscribeBtnLoaderTarget, this.subscribeBtnCheckTarget);
    this.emailInputTarget.style.borderColor = "#257d1B";
    const sMessage = this.successMessageTarget;
    sMessage.role = "status";
    sMessage.classList.remove("hidden");
    sMessage.innerHTML = message;
  }

  toggleDisable(element) {
    element.classList.toggle("disabled");
  }

  toggleBtnContent(element1, element2) {
    element1.classList.toggle("hidden");
    element2.classList.toggle("hidden");
  }

  resetToInitialState() {
    this.emailInputTarget.style.borderColor = "rgba(0,0,0,0)";
    // Reset ARIA to newsletter email format
    this.emailInputTarget.setAttribute("aria-describedby", "newsletteremail-format");
    const eMessage = this.errorMessageTarget;
    eMessage.classList.toggle("hidden", !eMessage.classList.contains("hidden"));
  }

  replaceTokenInDom(token) {
    document.querySelectorAll('input[name="authenticity_token"]').forEach((e) => {
      e.value = token;
    });
  }
}
