import "./mugshot_component.scss";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["iframe"];

  play(e) {
    e.preventDefault();

    this.insertPlayer();
    this.element.classList.add("is-playing");
    this.dispatchPlayEvent();
  }

  destroy(e) {
    if (e.detail.element == this.element || !this.hasIframeTarget) return;
    e.preventDefault();

    this.destroyPlayer();
    this.element.classList.remove("is-playing");
  }

  // PRIVATE
  insertPlayer() {
    this.element.insertAdjacentHTML("beforeend", this.playerHtml);
  }

  destroyPlayer() {
    this.iframeTarget.parentNode.removeChild(this.iframeTarget);
  }

  dispatchPlayEvent() {
    window.dispatchEvent(this.playEvent);
  }

  get playerHtml() {
    const { embedUrl } = this.element.dataset;
    const height = this.element.clientHeight;
    const width = this.element.clientWidth;

    return `<iframe data-target="qfap--mugshot.iframe" style="width: ${width}px; height: ${height}px; margin: 0; border: none;" src="${embedUrl}?enablejsapi=1&autoplay=1&controls=0&modestbranding=1&color=white&iv_load_policy=3" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope" allowfullscreen></iframe>`;
  }

  get playEvent() {
    return new CustomEvent("qfap--mugshot:play", {
      detail: {
        element: this.element,
      },
    });
  }
}
