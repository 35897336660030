/* global gon */
import "./map_component.scss";
import "./jomap_component.scss";

import { Controller as BaseController } from "stimulus";
import L from "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import _get from "lodash/get";

import "./calendar_control";

const DEFAULT_ZOOM = 14;
const DEFAULT_LAYER_ATTRIBUTION = 'Map data &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
const TILE_LAYER = "https://cdn.paris.fr/leaflet/paris/{z}/{x}/{y}.png";
const TILE_LAYER_MIN_ZOOM = 11;

L.Marker.prototype.options.icon = L.icon({
  iconUrl: require("./markers/default.svg"),
  iconSize: [21, 32],
  iconAnchor: [11, 32],
});

var pp24Marker = L.icon({
  iconUrl: require("./markers/pp24/default.svg"),
  iconSize: [21, 32],
  iconAnchor: [11, 32],
});

// JO MARKERS

var blueMarker = L.icon({
  iconUrl: require("./markers/jo2024/blue.svg"),
  iconSize: [31, 37],
  iconAnchor: [15.5, 37],
  popupAnchor: [0, -41],
});
var purpleMarker = L.icon({
  iconUrl: require("./markers/jo2024/purple.svg"),
  iconSize: [31, 37],
  iconAnchor: [15.5, 37],
  popupAnchor: [0, -41],
});
var plageMarker = L.icon({
  iconUrl: require("./markers/jo2024/plage.svg"),
  iconSize: [31, 37],
  iconAnchor: [15.5, 37],
  popupAnchor: [0, -41],
});
var competitionMarker = L.icon({
  iconUrl: require("./markers/jo2024/competition.svg"),
  iconSize: [41, 29],
  iconAnchor: [20.5, 29],
  popupAnchor: [0, -32],
});
var palaisMarker = L.icon({
  iconUrl: require("./markers/jo2024/grand_palais.svg"),
  iconSize: [44, 30],
  iconAnchor: [22, 30],
  popupAnchor: [0, -34],
});
var invalidesMarker = L.icon({
  iconUrl: require("./markers/jo2024/invalides.svg"),
  iconSize: [22, 46],
  iconAnchor: [11, 46],
  popupAnchor: [0, -50],
});
var concordeMarker = L.icon({
  iconUrl: require("./markers/jo2024/concorde.svg"),
  iconSize: [18, 53],
  iconAnchor: [9, 53],
  popupAnchor: [0, -56],
});
var eiffelMarker = L.icon({
  iconUrl: require("./markers/jo2024/toureiffel.svg"),
  iconSize: [21, 63],
  iconAnchor: [11, 63],
  popupAnchor: [0, -67],
});
var pontMarker = L.icon({
  iconUrl: require("./markers/jo2024/pont.svg"),
  iconSize: [44, 21],
  iconAnchor: [22, 21],
  popupAnchor: [0, -25],
});
var mairieMarker = L.icon({
  iconUrl: require("./markers/jo2024/mairie.svg"),
  iconSize: [31, 37],
  iconAnchor: [15.5, 37],
  popupAnchor: [0, -41],
});
var pointsMarker = L.icon({
  iconUrl: require("./markers/jo2024/points.svg"),
  iconSize: [40, 40],
  iconAnchor: [20, 20],
  popupAnchor: [0, -25],
});
var pointsParaMarker = L.icon({
  iconUrl: require("./markers/jo2024/points-para.svg"),
  iconSize: [40, 40],
  iconAnchor: [20, 20],
  popupAnchor: [0, -25],
});

export class Controller extends BaseController {
  static targets = [];

  // LIFECYCLE

  initialize() {
    this._map = null;
    this._options = {
      zoom: DEFAULT_ZOOM,
      scrollWheelZoom: false,
      zoomControl: false,
      attributionControl: false,
    };

    this.$mapDisclosure = null;
    this.$mapDisclosureContent = null;

    if (this.JOGeoJSON) {
      this._options.zoomControl = true;

      const wrapper = document.createElement("div");
      wrapper.id = "jo-map";
      this.element.parentNode.insertBefore(wrapper, this.element);
      wrapper.appendChild(this.element);
      this.element.classList.add("jo-map");
    }
  }

  connect() {
    if (this.JOGeoJSON) {
      this.$mapDisclosure = this.element.parentNode.parentNode.querySelector(".disclosure");
      this.$mapDisclosureContent = this.$mapDisclosure.querySelector(".disclosure-content");
    } else {
      this.$mapDisclosure = this.element.parentNode.querySelector(".disclosure");
      this.$mapDisclosureContent = this.$mapDisclosure.querySelector(".disclosure-content");
    }

    this._initMap();
  }

  // GETTERS / SETTERS

  get center() {
    return L.latLng(this.data.get("center").split(","));
  }

  get markers() {
    return JSON.parse(this.data.get("markers"));
  }

  get geoJSON() {
    return gon.geoJSON;
  }

  get JOGeoJSON() {
    return gon.JOGeoJSON;
  }

  get hasFilters() {
    const filtersValue = this.data.get("filters");
    return !(filtersValue == "0" || filtersValue == "false");
  }

  // PRIVATE

  _initMap() {
    this._map = L.map(this.element, this._options);

    // tile layer
    L.tileLayer(TILE_LAYER, {
      minZoom: TILE_LAYER_MIN_ZOOM,
      attribution: DEFAULT_LAYER_ATTRIBUTION,
    }).addTo(this._map);

    this._map.setView(this.center);

    if (this.markers.length > 0) {
      this._addMarkers();
    } else if (this.geoJSON && !this.hasFilters) {
      this._addGeoJSON();
    } else if (this.geoJSON && this.hasFilters) {
      this._addFilteredGeoJSON();

      const center = this.center;
      center.lat += 0.01; // re-center map

      this._map.setView(center, window.matchMedia("(min-width:640px)").matches ? 12 : 11);
    } else if (this.JOGeoJSON) {
      this._addJOGeoJSON();

      const center = this.center;
      if (window.matchMedia("(max-width:640px)").matches) {
        center.lat += 0.005; // re-center map on mobile
      }
      this._map.invalidateSize();
      this._map.setView(center, window.matchMedia("(min-width:640px)").matches ? 12 : 11);
    } else {
      // default controls
      this._addDefaultControls();
    }
  }

  _addDefaultControls(attributionConfig, zoomConfig) {
    // attribution control
    L.control.attribution(attributionConfig).addTo(this._map);

    // zoom control
    L.control.zoom(zoomConfig).addTo(this._map);
  }

  _addMarkers() {
    // default controls
    this._addDefaultControls();

    const group = new L.featureGroup();
    this.markers.forEach((m) => L.marker(m).addTo(group));
    group.addTo(this._map);

    const bounds = group.getBounds();

    if (Object.keys(bounds).length > 0) {
      this._map.fitBounds(group.getBounds(), { padding: [30, 30], maxZoom: this._options.zoom });
    }
  }

  _addJOGeoJSON() {
    const controlsDiv = document.createElement("div");
    controlsDiv.id = "map-pane";
    this.element.parentNode.appendChild(controlsDiv);

    this._overlayConfig = {
      competition: "Lieux de compétition",
      celebration: "Paris fête les jeux",
      parcours: "Parcours",
      points: "Points",
    };

    this._clusterGroup = L.markerClusterGroup({
      showCoverageOnHover: false,
      maxClusterRadius: 60,
      iconCreateFunction: (cluster) => {
        const isPara = _get(cluster.getAllChildMarkers(), "[0].feature.properties.date_") === "28/08/2024";
        return L.divIcon({
          className: isPara ? "cluster-marker-jo-para" : "cluster-marker-jo",
          html: `<div class="cluster-count-jo">${cluster.getChildCount()}</div>`,
          iconSize: [30, 30],
          iconAnchor: [15, 30],
        });
      },
    });

    this._map.addLayer(this._clusterGroup);

    this._layers = [];

    // attribution control
    L.control.attribution({ position: "topright" }).addTo(this._map);

    // layers control
    this._layersControl = L.control.layers({}, {}, { collapsed: false, position: "bottomright" }).addTo(this._map);

    // Add Calendar control
    this._calendarControl = L.control.calendar().addTo(this._map);

    this._layersControl._container.remove();
    this._calendarControl._container.remove();

    document.getElementById("map-pane").appendChild(this._layersControl.onAdd(this._map));
    document.getElementById("map-pane").appendChild(this._calendarControl.onAdd(this._map));

    this._calendarListener();

    this._calendarControl.container.querySelector(".calendar-container").style.height =
      this._calendarControl.container.querySelector(".calendar-container").clientHeight + "px";

    // Add layers
    this._addJOLayers(this._layersControl);
  }

  _addJOLayers() {
    Object.keys(this._overlayConfig).forEach((overlayKey) => {
      var overlayLayer = new L.geoJSON(this.JOGeoJSON, {
        style: (feature) => {
          if (feature.geometry.type === "MultiLineString" && feature.properties.category === "or") {
            return {
              color: "#AE8C0B",
              weight: 4,
              opacity: 1,
              lineJoin: "round",
            };
          } else if (feature.geometry.type === "MultiLineString" && feature.properties.category === "flamme") {
            return {
              color: "#33D2FD",
              weight: 4,
              opacity: 1,
              lineJoin: "round",
            };
          } else if (feature.geometry.type === "MultiLineString" && feature.properties.category === "para") {
            return {
              color: "#17B7B0",
              weight: 4,
              opacity: 1,
              lineJoin: "round",
            };
          }
        },
        filter: (feature, _layer) => {
          if (
            (feature.geometry.type === "MultiLineString" || feature.properties.date_ === "25/07/2024") &&
            feature.properties.overlay == overlayKey
          ) {
            const dateMatches = document.querySelectorAll(`[data-date="${feature.properties.date_}"]`);
            dateMatches.forEach(function (dateItem) {
              const dotsContainer = dateItem.getElementsByClassName(`dots`)[0];
              dateItem.classList.add("has-event");
              if (dotsContainer.getElementsByClassName(feature.properties.category).length === 0) {
                const dot = document.createElement(`span`);
                dot.classList.add(feature.properties.category);
                dotsContainer.append(dot);
              }
            });
            return this._calendarControl._date.toLocaleDateString("fr") == feature.properties.date_;
          }
          if (
            overlayKey === "points" &&
            feature.properties.overlay == overlayKey &&
            (this._calendarControl._date.toLocaleDateString("fr") === "14/07/2024" ||
              this._calendarControl._date.toLocaleDateString("fr") === "15/07/2024" ||
              this._calendarControl._date.toLocaleDateString("fr") === "25/07/2024" ||
              this._calendarControl._date.toLocaleDateString("fr") === "26/07/2024" ||
              this._calendarControl._date.toLocaleDateString("fr") === "28/08/2024")
          ) {
            return this._calendarControl._date.toLocaleDateString("fr") == feature.properties.date_;
          }

          return (
            feature.properties.overlay == overlayKey &&
            overlayKey !== "points" &&
            this._calendarControl._date.toLocaleDateString("fr") !== "14/07/2024" &&
            this._calendarControl._date.toLocaleDateString("fr") !== "15/07/2024" &&
            this._calendarControl._date.toLocaleDateString("fr") !== "28/08/2024"
          );
        },
        pointToLayer: (feature, latlng) => {
          if (feature.properties.category === "grand_palais") {
            return L.marker(latlng, { icon: palaisMarker });
          } else if (feature.properties.category === "purple") {
            return L.marker(latlng, { icon: purpleMarker });
          } else if (feature.properties.category === "competition") {
            return L.marker(latlng, { icon: competitionMarker });
          } else if (feature.properties.category === "invalides") {
            return L.marker(latlng, { icon: invalidesMarker });
          } else if (feature.properties.category === "concorde") {
            return L.marker(latlng, { icon: concordeMarker });
          } else if (feature.properties.category === "toureiffel") {
            return L.marker(latlng, { icon: eiffelMarker });
          } else if (feature.properties.category === "pont") {
            return L.marker(latlng, { icon: pontMarker });
          } else if (feature.properties.category === "mairie") {
            return L.marker(latlng, { icon: mairieMarker });
          } else if (feature.properties.category === "blue") {
            return L.marker(latlng, { icon: blueMarker });
          } else if (feature.properties.category === "plage") {
            return L.marker(latlng, { icon: plageMarker });
          } else if (feature.properties.category === "points") {
            if (feature.properties.date_ === "28/08/2024") {
              return L.marker(latlng, { icon: pointsParaMarker });
            }
            return L.marker(latlng, { icon: pointsMarker });
          }
        },
        onEachFeature: (feature, layer) => {
          if (feature.properties && feature.properties.content) {
            const popupContent = this._popupTemplate(feature.properties);
            layer.bindPopup(popupContent);
            this._appendAccessibleFeature(feature.properties);
          }
        },
      });
      overlayLayer._id = overlayKey;
      this._layers.push(overlayLayer);
      if (overlayKey !== "points") {
        overlayLayer.addTo(this._map);
        const controlMarkup = `<div class="overlay-control overlay-control--${overlayKey}">${this._overlayConfig[overlayKey]} <span></span></div>`;
        this._layersControl.addOverlay(overlayLayer, controlMarkup);
      } else {
        this._clusterGroup.addLayer(overlayLayer);
      }
    });
  }

  _calendarListener() {
    const calendar = this._calendarControl;
    const layers = this._layers;
    const dataJO = this.JOGeoJSON;
    const clusterGroup = this._clusterGroup;

    // Define listener function
    function dayClickListener(event) {
      let sameElement = null;
      event.target.classList.toggle("active");
      calendar.container.querySelector(".current-date span").innerHTML = new Date().toLocaleDateString("fr", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      calendar.container.querySelectorAll(`.calendar-dates li`).forEach((elem) => {
        if (elem !== event.target) {
          elem.classList.remove("active");
        } else if (!elem.classList.contains("active")) {
          sameElement = elem;
        }
      });
      if (event.target !== sameElement) {
        const clickedDate = event.target.getAttribute("data-date").split("/");
        calendar._date = new Date(clickedDate[2], parseInt(clickedDate[1]) - 1, clickedDate[0]);
        calendar.container.querySelector(".current-date span").innerHTML = new Date(
          clickedDate[2],
          parseInt(clickedDate[1]) - 1,
          clickedDate[0]
        ).toLocaleDateString("fr", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      } else {
        calendar._date = new Date();
        if (calendar._date.getMonth() !== calendar._month) {
          calendar.container.querySelector(`input[name="tabset"][value="${calendar._date.getMonth()}"]`).click();
        } else {
          calendar.container.querySelector(".calendar-dates li.today").classList.add("active");
        }
      }
      layers.forEach((layer) => {
        clusterGroup.clearLayers();
        layer.clearLayers();
      });
      layers.forEach((layer) => {
        if (layer._id !== "points") {
          layer.addData(dataJO);
        } else {
          layer.addData(dataJO).addTo(clusterGroup);
        }
      });
    }

    // set initial listeners
    calendar.container.querySelectorAll(`.calendar-dates li`).forEach((elem) => {
      elem.addEventListener("click", dayClickListener);
    });

    if (calendar.container.querySelector('input[name="tabset"]')) {
      calendar.container.querySelectorAll('input[name="tabset"]').forEach((elem) => {
        elem.addEventListener("change", function (event) {
          calendar._month = parseInt(event.target.value);
          calendar._date = new Date();
          //remove obsolete listeners
          calendar.container.querySelectorAll(`.calendar-dates li`).forEach((elem) => {
            elem.removeEventListener("click", dayClickListener);
          });
          layers.forEach((layer) => {
            clusterGroup.clearLayers();
            layer.clearLayers();
          });
          calendar._manipulate();

          //set new listeners
          calendar.container.querySelectorAll(`.calendar-dates li`).forEach((elem) => {
            elem.addEventListener("click", dayClickListener);
          });
          layers.forEach((layer) => {
            if (layer._id !== "points") {
              layer.addData(dataJO);
            } else {
              layer.addData(dataJO).addTo(clusterGroup);
            }
          });
        });
      });
    }
  }

  _addGeoJSON() {
    // default controls
    this._addDefaultControls();

    const isPP24 = document.body.classList.contains("paris-plages-2024");

    if (isPP24) {
      const ppMap = window.pp_map;

      const polygonVillette = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [2.373262140088348, 48.88601966295468],
              [2.374270607215708, 48.88511664320359],
              [2.37836902256529, 48.88730010908811],
              [2.3780042421291947, 48.888347715566624],
              [2.373262140088348, 48.88601966295468],
            ],
          ],
        },
        properties: {
          title: ppMap?.villette?.title,
          url: ppMap?.villette?.url,
          content: ppMap?.villette?.content,
          image: ppMap?.villette?.image,
        },
      };
      const polygonSaintMartin = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [2.3636673021609838, 48.87293890412949],
              [2.3641930151424146, 48.87331996387557],
              [2.3634741831065806, 48.874441956273344],
              [2.369292693459228, 48.881338044265775],
              [2.368455844223481, 48.88146504317779],
              [2.3629090359226335, 48.87479716425532],
              [2.362812476395432, 48.874112686180084],
              [2.3636673021609838, 48.87293890412949],
            ],
          ],
        },
        properties: {
          title: ppMap?.saintMartin?.title,
          url: ppMap?.saintMartin?.url,
          content: ppMap?.saintMartin?.content,
          image: ppMap?.saintMartin?.image,
        },
      };
      const polygonSeine = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [2.3426382440186373, 48.858367056230115],
              [2.342475686377998, 48.858207329885936],
              [2.360567842470338, 48.85186463120709],
              [2.3608834348755727, 48.85078258938312],
              [2.361282077913763, 48.850935606818254],
              [2.36098309563512, 48.85200671577026],
              [2.3426382440186373, 48.858367056230115],
            ],
          ],
        },
        properties: {
          title: ppMap?.seine?.title,
          url: ppMap?.seine?.url,
          content: ppMap?.seine?.content,
          image: ppMap?.seine?.image,
        },
      };

      const polygons = [polygonVillette, polygonSaintMartin, polygonSeine];

      polygons.forEach((polygon) => {
        const polygonLayer = L.geoJSON(polygon, {
          style: {
            color: "#009CE0",
            fillColor: "#009CE0",
            fillOpacity: 0.2,
            weight: 2,
          },
          onEachFeature: (feature, layer) => {
            const popupContent = this._popupTemplate(feature.properties);
            layer.bindPopup(popupContent);
          },
        });
        polygonLayer.addTo(this._map);
      });
    }

    const group = new L.geoJSON(this.geoJSON, {
      pointToLayer: (feature, latlng) => {
        if (isPP24) {
          return L.marker(latlng, { icon: pp24Marker });
        } else {
          return L.marker(latlng);
        }
      },
      onEachFeature: (feature, layer) => {
        const popupContent = this._popupTemplate(feature.properties);
        layer.bindPopup(popupContent);
        this._appendAccessibleFeature(feature.properties);
      },
    });

    const clusterGroup = L.markerClusterGroup({
      showCoverageOnHover: false,
      maxClusterRadius: 10,
      iconCreateFunction: (cluster) => {
        return L.divIcon({
          className: "cluster-marker",
          html: cluster.getChildCount(),
          iconSize: [22, 28],
          iconAnchor: [11, 28],
        });
      },
    });
    clusterGroup.addLayer(group);
    this._map.addLayer(clusterGroup);

    const bounds = group.getBounds();

    if (Object.keys(bounds).length > 0) {
      this._map.fitBounds(group.getBounds(), { padding: [30, 30] });
    }
    this._addAccessibleFeatures();
  }

  _addFilteredGeoJSON() {
    // default controls
    this._addDefaultControls({}, { position: "bottomleft" });

    // layers control
    const layersControl = L.control.layers({}, {}, { collapsed: false }).addTo(this._map);

    if (!Array.isArray(this.geoJSON.features)) {
      return;
    }

    // Gathers every tag into a unique array
    let tags = [
      ...new Set(
        this.geoJSON.features.reduce((memo, feature) => {
          return memo.concat(_get(feature, "properties.universe_tags", []));
        }, [])
      ),
    ];

    // Populate layers
    tags.forEach((tag) => {
      // Initialize MarkerClusterGroup for each tag
      const clusterGroup = L.markerClusterGroup({
        showCoverageOnHover: false,
        maxClusterRadius: 0,
        iconCreateFunction: (cluster) => {
          return L.divIcon({
            className: "cluster-marker",
            html: `<div class="cluster-count">${cluster.getChildCount()}</div>`,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
          });
        },
      });

      var overlayLayer = new L.geoJSON(this.geoJSON, {
        filter: (feature, _layer) => {
          return feature.properties.universe_tags.includes(tag);
        },
        pointToLayer: (feature, latlng) => {
          return L.marker(latlng);
        },
        onEachFeature: (feature, layer) => {
          const popupContent = this._popupTemplate(feature.properties);
          layer.bindPopup(popupContent);
        },
      });

      // Add each feature to the cluster group
      overlayLayer.eachLayer((layer) => {
        clusterGroup.addLayer(layer);
      });

      this._map.addLayer(clusterGroup);

      const controlMarkup = `
      <span class="qfap--tag">
        <span class="qfap--tag-text">${tag}</span>
        <svg class="paris-icon paris-icon-qfap--tags-stroke" role="img"><use xlink:href="#paris-icon-qfap--tags-stroke"></use></svg>
      </span>`;

      layersControl.addOverlay(clusterGroup, controlMarkup);
    });

    const bounds = this._map.getBounds();
    if (Object.keys(bounds).length > 0) {
      this._map.fitBounds(bounds, { padding: [30, 30], maxZoom: this._options.zoom });
    }
  }

  _popupTemplate(prop) {
    let link = ``;
    if (prop.url) link = `<a href="${prop.url}" target="_blank">En savoir plus</a>`;

    let content = "";
    if (prop.content) {
      content = prop.content.replace(/\n/g, "<br>");
    } else if (prop.date) {
      content = prop.date;
    } else {
      content = "Aucune information";
    }

    return `
      <div class="marker-popup">
        ${
          prop.image
            ? `<div class="marker-popup-image"><img src="${prop.image}" alt="${prop.title || ""}" /></div>`
            : ""
        }
        <div class="marker-popup-content">
          <h3 data-color="${prop.category}">${prop.title || ""}</h3>
          <p>${content}</p>
          ${link}
        </div>
      </div>
    `;
  }

  // Accessibility
  _addAccessibleFeatures() {
    // If we have features to add into disclosure, we show the disclosure
    this.$mapDisclosure.classList.add("has-details");
  }

  // Append feature to the accessible features div
  _appendAccessibleFeature(prop) {
    let link = ``;
    if (prop.url) link = `<a href="${prop.url}" target="_blank">En savoir plus</a>`;

    let content = "";
    if (prop.content) {
      content = prop.content.replace(/\n/g, "<br>");
    } else if (prop.date) {
      content = prop.date;
    }

    // Skip empty property or unsupported
    if (link == "" || content == "") {
      return;
    }

    const htmlString = `<div class="map-disclosure-item">
      ${
        prop.image
          ? `<div class="map-disclosure-item-image"><img src="${prop.image}" alt="${prop.title || ""}" /></div>`
          : ""
      }
      <div class="map-disclosure-item-content">
        <h3 data-color="${prop.category}">${prop.title || ""}</h3>
        <p>${content}</p>
        ${link}
      </div>
    </div>`;

    const range = document.createRange();
    const documentFragment = range.createContextualFragment(htmlString);

    this.$mapDisclosureContent.appendChild(documentFragment);
  }
}
