const widget = (widgetOpts) => {
  let indexes = {};
  let defaultItem;
  let currentKey;

  return {
    init(_initOpts) {
      const { items, defaultItemKey } = widgetOpts;

      items.forEach((i) => {
        indexes[i.key] = i.index;
      });

      defaultItem = items.find((i) => i.key == (defaultItemKey || "pertinence"));
      currentKey = defaultItem.key;
    },
    render(renderOpts) {
      let distanceMessage = `<div class="ais-SortByRadios-message">déplacez la carte pour chercher d’un autre emplacement</div>`;

      widgetOpts.container.innerHTML = `
        ${widgetOpts.items
          .map(
            (item) => `
          <div class="ais-SortByRadios-item">
            <input type="radio" name="sort-by-radio" value="${item.key}" id="sort_by_${item.key}" class="ais-SortByRadios-radio"${
              item.key == currentKey ? ' checked="checked"' : ""
            } />
            <label class="ais-SortByRadios-item-label" for="sort_by_${item.key}">
            ${item.label}
            </label>
          </div>
          ${item.key == "distance" && item.key == currentKey ? distanceMessage : ""}
          `
          )
          .join("")}
      `;

      widgetOpts.container.querySelectorAll('input[type="radio"]').forEach((radio) => {
        radio.addEventListener("change", (e) => {
          e.preventDefault();
          const { target } = e;
          currentKey = target.value;

          if (target.checked) {
            if (currentKey == "distance") {
              const mapControlLocationElement = document.querySelector(
                ".paris-search-hits-map .paris-search-hits-map-control-location, .included-events-map .paris-search-hits-map-control-location"
              );
              // if a map with a location button is present, activate it
              if (mapControlLocationElement) {
                mapControlLocationElement.dispatchEvent(new Event("click", { bubbles: true }));
              } else {
                navigator.geolocation.getCurrentPosition(
                  (pos) => {
                    const latlng = `${pos.coords.latitude}, ${pos.coords.longitude}`;
                    renderOpts.helper.setIndex(indexes.pertinence).setQueryParameter("aroundLatLng", latlng).search();
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              }
            } else {
              renderOpts.helper.setIndex(indexes[currentKey]).setQueryParameter("aroundLatLng", undefined).search();
            }
          }
        });
      });
    },
    dispose() {
      const rootEl = widgetOpts.container;
      while (rootEl.firstChild) {
        rootEl.removeChild(rootEl.firstChild);
      }
    },
  };
};

export default widget;
