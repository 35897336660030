import "./exceptional_schedules_component.scss";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["content"];

  connect() {}

  toggle(e) {
    const isExpanded = e.target.getAttribute("aria-expanded") === "false" ? true : false;

    this.contentTargets.forEach((item) => {
      item.style.maxHeight = isExpanded ? `${item.scrollHeight}px` : "0px";
    });

    e.target.setAttribute("aria-expanded", isExpanded);
    e.target.innerText = isExpanded ? "Masquer le détail" : "Afficher le détail";

    this.contentTargets.forEach((item) => item.setAttribute("aria-expanded", isExpanded));
  }
}
