import "./disclosure_component.scss";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  toggle() {
    this.element.setAttribute("aria-expanded", !this.isExpanded);
  }

  get isExpanded() {
    return this.element.getAttribute("aria-expanded") === "true";
  }
}
