import L from "leaflet";
import { locationControl, sizeControl } from "./custom_controls";
import { numberedMarker, locationDot } from "./custom_markers";
import addNumbersToItems from "./add_numbers_to_items";

const TILE_LAYER = "https://cdn.paris.fr/leaflet/paris/{z}/{x}/{y}.png";
const TILE_LAYER_ATTRIBUTION = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
const INITIAL_CENTER = {
  lat: 48.856666,
  lng: 2.342222,
};
const INITIAL_ZOOM = 12;

let algoliaHelper;
let map;
let mapContainer;
let resizable = false;
let markersLayer;
let refineAroundCenterEnabled = false;
let userLocationMarker;
let userLocation;

// Initialize the map
// - create the div that will render the map
// - add it to the DOM
// - initialize the map
// - add the tiles layer
// - add the custom controls (zoom, location, size)
// - add the event listeners
const initMap = () => {
  map = L.map(mapContainer, {
    center: INITIAL_CENTER,
    zoom: INITIAL_ZOOM,
    zoomControl: false,
    scrollWheelZoom: false
  });

  L.tileLayer(TILE_LAYER, { attribution: TILE_LAYER_ATTRIBUTION }).addTo(map);

  // Controls
  L.control
    .zoom({
      position: "topright",
      zoomInTitle: "Zoom avant",
      zoomOutTitle: "Zoom arrière",
    })
    .addTo(map);

  new locationControl({ position: "topright" }).addTo(map);

  if (resizable) {
    new sizeControl({ position: "topleft" }).addTo(map);
  }

  // Event listeners
  map.on("dragend", (_e) => {
    if (refineAroundCenterEnabled) {
      refineAroundCenter();
    }
  });

  map.on("moveend", (_e) => {
    if (userLocation) {
      mapContainer.classList.toggle("is-centered", map.getCenter().equals(userLocation, 0.001));
    }
  });

  map.on("locationfound", (e) => {
    userLocation = e.latlng;

    removeHits();
    renderUserLocationMarker();
    refineAroundLatLng(e.latlng);

    refineAroundCenterEnabled = true;
    mapContainer.classList.remove("location-unknown", "location-denied");
    mapContainer.classList.add("is-located", "is-centered");
  });

  map.on("locationerror", (_e) => {
    userLocation = null;
    mapContainer.classList.remove("is-located", "location-unknown");
    mapContainer.classList.add("location-denied");
  });
};

// Filter hits to keep only the ones we want to display on the map
// - only hits with geoloc
// - only one hit per location (= deduplicate)
const filterRenderableHits = (hits) => {
  const geolocatedHits = hits.filter((hit) => hit._geoloc && hit._geoloc.lat != 0 && hit._geoloc.lng != 0);
  const uniqueHits = geolocatedHits.filter(
    (v, i, a) => a.findIndex((t) => t._geoloc.lat === v._geoloc.lat && t._geoloc.lng === v._geoloc.lng) === i
  );

  return uniqueHits;
};

const enterMarker = (e) => {
  toggleMarker(e.sourceTarget, true);
};

const leaveMarker = (e) => {
  toggleMarker(e.sourceTarget, false);
};

const toggleMarker = (marker, bool) => {
  const icon = marker._icon;
  const hitNumber = icon.getAttribute("data-hit-number");
  const hitElements = document.querySelectorAll(
    `.paris-search-hits-item.has-number[data-hit-number="${hitNumber}"], .blocks--reference.has-number[data-hit-number="${hitNumber}"]`
  );
  hitElements.forEach((el) => el.classList.toggle("is-active", bool));
};

// Add markers for hits to the map
// - remove existing markers
// - add one marker per geolocated hit (deduplicated), with custom numbered icon
const renderHits = (hits) => {
  const hitsToRender = addNumbersToItems(filterRenderableHits(hits));

  removeHits();

  if (hitsToRender.length > 0) {
    markersLayer = L.featureGroup();

    hitsToRender.forEach(({ _geoloc, number, itemId }) => {
      L.marker([_geoloc.lat, _geoloc.lng], {
        icon: new numberedMarker({ number: number, itemId: itemId }),
      }).addTo(markersLayer);
    });

    markersLayer.on("mouseover", enterMarker).on("mouseout", leaveMarker).addTo(map);

    map.fitBounds(markersLayer.getBounds(), { padding: [30, 30] });
  }
};

// Remove all hits from the map
const removeHits = () => {
  if (markersLayer) {
    markersLayer.clearLayers();
    markersLayer.removeFrom(map);
    markersLayer = null;
  }
};

// Refine hits around a given latlng
const refineAroundLatLng = (latlng) => {
  const { lat, lng } = latlng;
  algoliaHelper.setQueryParameter("aroundLatLng", lat + "," + lng).search();
};

// Refine hits around the current map center
const refineAroundCenter = () => {
  refineAroundLatLng(map.getCenter());
};

// Render a marker on the map for the user location
const renderUserLocationMarker = () => {
  if (userLocationMarker) userLocationMarker.remove();

  // We add a timeout to prevent a huge dot to appear before the map is zoomed
  setTimeout(() => {
    userLocationMarker = locationDot(userLocation).addTo(map);
  }, 500);
};

// If the user location is known, center the map on it
const centerOnUserLocation = () => {
  if (navigator.permissions && navigator.permissions.query) {
    navigator.permissions.query({ name: "geolocation" }).then(function (result) {
      if (result.state == "granted") {
        navigator.geolocation.getCurrentPosition((e) => {
          userLocation = L.latLng(e.coords.latitude, e.coords.longitude);
          renderUserLocationMarker();
          mapContainer.classList.add("is-located", "is-centered");
        });
      } else if (result.state == "prompt") {
        mapContainer.classList.add("location-unknown");
      } else if (result.state == "denied") {
        mapContainer.classList.add("location-denied");
      }
    });
  }
};

// Algolia instantsearch.js custom widget
// see https://www.algolia.com/doc/guides/building-search-ui/widgets/create-your-own-widgets/js/
const widget = (widgetOpts) => {
  return {
    init(initOpts) {
      algoliaHelper = initOpts.helper;

      mapContainer = widgetOpts.container;
      resizable = widgetOpts.resizable;

      initMap();
      centerOnUserLocation();
    },
    render(renderOpts) {
      const { results } = renderOpts;

      renderHits(results.hits);
    },
    dispose() {
      if (map) {
        map.off();
        map.remove();
        map = undefined;
      }
    },
  };
};

export default widget;
