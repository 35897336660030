import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  get gif() {
    return this.data.get("gif");
  }

  connect() {
    if (this.gif) {
      let img = new Image();

      img.onload = () => {
        this.element.src = img.src;
        img = null;
      };

      img.src = this.gif;
    }
  }
}
