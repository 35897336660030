import { Controller as BaseController } from "stimulus";
import "./telex_item_component.scss";

import { decodeMails } from "../../assets/javascript/utils/mailsHelper";

export class Controller extends BaseController {
  static targets = ["textContainer"];

  connect() {
    decodeMails(this.hasTextContainerTarget, this.textContainerTarget);
  }
}