import L from "leaflet";
import markerSvg from "./marker.svg";

const numberedMarker = L.Icon.extend({
  options: {
    iconUrl: markerSvg,
    number: '',
    shadowUrl: null,
    iconSize: new L.Point(22, 28),
    iconAnchor: new L.Point(11, 28),
    className: 'paris-search-hits-item-number',
  },
  createIcon: function () {
    const number = this.options['number'];
    const itemId = this.options['itemId'];
    let link = document.createElement('a');
    link.href = itemId ? `#${itemId}` : "#";
    link.dataset.hitNumber = number;
    link.innerHTML = number || '';
    this._setIconStyles(link, 'icon');
    return link;
  },
  createShadow: function () { return null; }
});

const locationDot = (latlng) => L.circleMarker(latlng, {
  color: "#354BCF",
  fillColor: "#354BCF",
  fillOpacity: 0.6,
  interactive: false,
  opacity: 0.15,
  radius: 8,
  weight: 100,
});

export {
  numberedMarker,
  locationDot,
};
