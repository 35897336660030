import instantsearch from "instantsearch.js";
import { linkForMairie, linkForGlobal } from "../utils";
import icon from "../../../assets/javascript/utils/icon_helper";

const truncateStr = (str, length) => {
  if (!str) return "";
  return str.length > length ? str.substring(0, length) + "..." : str;
};

const tags = (hit) => {
  return hit.tags && hit.tags.length
    ? `<ul class="qfap--tags paris-search-hits-item-tags">${hit.tags
        .map((t) => {
          const link = linkForGlobal(t, "path");
          return `<li><a class="qfap--tag" href="${link.href}" target="${link.target}"><span class="qfap--tag-text">${
            t.name
          }</span>${icon("qfap/tags-stroke")}${icon("qfap/tags-ring")}</a></li>`;
        })
        .join("")}</ul>`
    : "";
};

export default {
  everything: {
    empty: `<p role="alert">Aucun résultat ne correspond à « {{ query }} »</p>`,
    item: (hit) => {
      const link = linkForMairie(hit, "path");
      const target = link.target ? `target="${link.target}"` : "";
      return `
      <div class="paris-search-hits-item">
        <a class="paris-search-hits-item-link" href="${link.href}" ${target}>
          <div class="paris-search-hits-item-content">
            ${hit.kind ? `<div class="tag">${hit.kind}</div>` : ""}
            <div class="paris-search-hits-item-title">
              ${instantsearch.highlight({ attribute: "title", hit })}
            </div>
            ${
              hit.date_details
                ? `<div class="paris-search-hits-item-date">${icon("search/calendar", "Calendrier")}<span>${
                    hit.date_details
                  }</span></div>`
                : ""
            }
            ${
              !hit.date_details && hit.updated_at_formatted
                ? `<div class="paris-search-hits-item-update">Mise à jour le ${hit.updated_at_formatted}</div>`
                : ""
            }
            ${
              hit.place
                ? `<div class="paris-search-hits-item-place">${icon("search/minimarker", "Lieu")}<span>${
                    hit.place
                  }</span></div>`
                : ""
            }
            <div class="paris-search-hits-item-text">
              ${instantsearch.snippet({ attribute: "content", hit }) || truncateStr(hit.content, 200)}
            </div>
            ${
              hit.open_details
                ? `<div class="paris-search-hits-item-open-details is-${hit.open_details.color}">${hit.open_details.text}</div>`
                : ""
            }
          </div>
          <div class="paris-search-hits-item-image">
            ${hit.image ? `<img alt="" src="${hit.image}" loading="lazy">` : ""}
          </div>
        </a>
        ${tags(hit)}
      </div>`;
    },
  },
  info: {
    empty: `<p role="alert">Aucune info ou actu pour « {{ query }} »</p>`,
    item: (hit) => {
      const link = linkForMairie(hit, "path");
      const target = link.target ? `target="${link.target}"` : "";
      return `
      <div class="paris-search-hits-item">
        <a class="paris-search-hits-item-link" href="${link.href}" ${target}>
          <div class="paris-search-hits-item-content">
            ${hit.format ? `<div class="tag">${hit.format}</div>` : ""}
            <div class="paris-search-hits-item-title">
              ${instantsearch.highlight({ attribute: "title", hit })}
            </div>
            ${
              hit.date_details
                ? `<div class="paris-search-hits-item-date">${icon("search/calendar", "Calendrier")}<span>${
                    hit.date_details
                  }</span></div>`
                : ""
            }
            ${
              !hit.date_details && hit.updated_at_formatted
                ? `<div class="paris-search-hits-item-update">Mise à jour le ${hit.updated_at_formatted}</div>`
                : ""
            }
            ${
              hit.place
                ? `<div class="paris-search-hits-item-place">${icon("search/minimarker", "Lieu")}<span>${
                    hit.place
                  }</span></div>`
                : ""
            }
            <div class="paris-search-hits-item-text">
              ${instantsearch.snippet({ attribute: "content", hit }) || truncateStr(hit.content, 200)}
            </div>
            ${
              hit.open_details
                ? `<div class="paris-search-hits-item-open-details is-${hit.open_details.color}">${hit.open_details.text}</div>`
                : ""
            }
          </div>
          <div class="paris-search-hits-item-image">
            ${hit.image ? `<img alt="" src="${hit.image}" loading="lazy">` : ""}
          </div>
        </a>
        ${tags(hit)}
      </div>`;
    },
  },
  sortir: {
    empty: `<p role="alert">Aucune sortie pour « {{ query }} »</p>`,
    item: (hit) => {
      const link = linkForMairie(hit, "path");
      const target = link.target ? `target="${link.target}"` : "";
      return `<div class="paris-search-hits-item${hit.number ? " has-number" : ""}"${
        hit.itemId ? ` id="${hit.itemId}"` : ""
      }${
        hit.number
          ? ` data-hit-number="${hit.number}" data-action="mouseenter->search#enterHit mouseleave->search#leaveHit"`
          : ""
      }>
        <a class="paris-search-hits-item-link" href="${link.href}" ${target}>
          <div class="paris-search-hits-item-content">
            ${hit.kind ? `<div class="tag">${hit.kind}</div>` : ""}
            ${hit.number ? `<div class="paris-search-hits-item-number">${hit.number}</div>` : ""}
            <div class="paris-search-hits-item-title">
              ${instantsearch.highlight({ attribute: "title", hit })}
            </div>
            ${
              hit.date_details
                ? `<div class="paris-search-hits-item-date">${icon("search/calendar", "Calendrier")}<span>${
                    hit.date_details
                  }</span></div>`
                : ""
            }
            ${
              !hit.date_details && hit.updated_at_formatted
                ? `<div class="paris-search-hits-item-update">Mise à jour le ${hit.updated_at_formatted}</div>`
                : ""
            }
            ${
              hit.place
                ? `<div class="paris-search-hits-item-place">${icon("search/minimarker", "Lieu")}<span>${
                    hit.place
                  }</span></div>`
                : ""
            }
            <div class="paris-search-hits-item-text">
              ${instantsearch.snippet({ attribute: "content", hit }) || truncateStr(hit.content, 200)}
            </div>
            ${
              hit.open_details
                ? `<div class="paris-search-hits-item-open-details is-${hit.open_details.color}">${hit.open_details.text}</div>`
                : ""
            }
          </div>
          <div class="paris-search-hits-item-image">
            ${hit.image ? `<img alt="" src="${hit.image}" loading="lazy">` : ""}
          </div>
        </a>
        ${tags(hit)}
      </div>`;
    },
  },
  activities: {
    empty: `<p role="alert">Aucune activité pour « {{ query }} »</p>`,
    item: (hit) => {
      const link = linkForMairie(hit, "path");
      const target = link.target ? `target="${link.target}"` : "";
      return `<div class="paris-search-hits-item${hit.number ? " has-number" : ""}"${
        hit.itemId ? ` id="${hit.itemId}"` : ""
      }${
        hit.number
          ? ` data-hit-number="${hit.number}" data-action="mouseenter->search#enterHit mouseleave->search#leaveHit"`
          : ""
      }>
        <a class="paris-search-hits-item-link" href="${link.href}" ${target}>
          <div class="paris-search-hits-item-content">
            ${hit.number ? `<div class="paris-search-hits-item-number">${hit.number}</div>` : ""}
            <div class="paris-search-hits-item-title">
              ${instantsearch.highlight({ attribute: "title", hit })}
            </div>
            ${
              hit.date_details
                ? `<div class="paris-search-hits-item-date">${icon("search/calendar", "Calendrier")}<span>${
                    hit.date_details
                  }</span></div>`
                : ""
            }
            ${
              !hit.date_details && hit.updated_at_formatted
                ? `<div class="paris-search-hits-item-update">Mise à jour le ${hit.updated_at_formatted}</div>`
                : ""
            }
            ${
              hit.audience_details
                ? `<div class="paris-search-hits-item-audience">${icon("search/audience", "Public")}${
                    hit.audience_details
                  }</div>`
                : ""
            }
            ${
              hit.place
                ? `<div class="paris-search-hits-item-place">${icon("search/minimarker", "Lieu")}<span>${
                    hit.place
                  }</span></div>`
                : ""
            }
            <div class="paris-search-hits-item-text">
              ${instantsearch.snippet({ attribute: "content", hit }) || truncateStr(hit.content, 200)}
            </div>
            ${
              hit.open_details
                ? `<div class="paris-search-hits-item-open-details is-${hit.open_details.color}">${hit.open_details.text}</div>`
                : ""
            }
          </div>
          <div class="paris-search-hits-item-image">
            ${hit.image ? `<img alt="" src="${hit.image}" loading="lazy">` : ""}
          </div>
        </a>
        ${tags(hit)}
      </div>`;
    },
  },
  places: {
    empty: `<p role="alert">Aucun lieu ne correspond à « {{ query }} »</p>`,
    item: (hit) => {
      const link = linkForMairie(hit, "url");
      const target = link.target ? `target="${link.target}"` : "";
      return `<div class="paris-search-hits-item${hit.number ? " has-number" : ""}"${
        hit.itemId ? ` id="${hit.itemId}"` : ""
      }${
        hit.number
          ? ` data-hit-number="${hit.number}" data-action="mouseenter->search#enterHit mouseleave->search#leaveHit"`
          : ""
      }>
        <a class="paris-search-hits-item-link" href="${link.href}" ${target}>
          <div class="paris-search-hits-item-content">
            ${hit.number ? `<div class="paris-search-hits-item-number">${hit.number}</div>` : ""}
            <div class="paris-search-hits-item-title">
              ${instantsearch.highlight({ attribute: "name", hit })}
            </div>
            ${
              hit.address_street
                ? `<div class="paris-search-hits-item-place">${icon("search/minimarker", "Lieu")}<span>${
                    hit.address_street
                  }, ${hit.address_district || hit.address_city}</span></div>`
                : ""
            }
            <div class="paris-search-hits-item-text">
              ${instantsearch.snippet({ attribute: "content", hit }) || truncateStr(hit.content, 200)}
            </div>
            ${
              hit.open_details && hit.open_details.text
                ? `<div class="paris-search-hits-item-open-details is-${hit.open_details.color}">${hit.open_details.text}</div>`
                : ""
            }
          </div>
          <div class="paris-search-hits-item-image">
            ${hit.image ? `<img alt="" src="${hit.image}" loading="lazy">` : ""}
          </div>
        </a>
      </div>`;
    },
  },
  modal: {
    mairies: {
      item: (hit) => {
        return `
          <div class="paris-search-modal-mairie-hit">
            <div class="paris-search-modal-mairie-hit-title">${hit.name} :</div>
            <a href="${hit.url}" target="_blank" class="paris-button is-blue">
              <span class="paris-button-text">Aller sur le site ${icon("external")}</span>
            </a>
            <div class="paris-search-modal-mairie-hit-links">
              <a href="${hit.url}services" target="_blank" class="paris-button is-transparent as-link">
              <span class="paris-button-text">Services ${icon("external")}</span>
              </a>
              <a href="${hit.url}municipalite" target="_blank" class="paris-button is-transparent as-link">
              <span class="paris-button-text">Municipalité ${icon("external")}</span>
              </a>
              <a href="${hit.url}vie-citoyenne" target="_blank" class="paris-button is-transparent as-link">
              <span class="paris-button-text">Vie citoyenne ${icon("external")}</span>
              </a>
            </div>
          </div>
        `;
      },
    },
    info: {
      empty: `<p role="alert">Aucune info ou actu pour « {{ query }} »</p>`,
      item: (hit) => {
        const link = linkForMairie(hit, "path");
        const target = link.target ? `target="${link.target}"` : "";
        return `
          <a href="${link.href}" ${target} class="paris-search-modal-hit-link">
            <div class="paris-search-modal-hit-content">
              <div class="paris-search-modal-hit-title">${instantsearch.highlight({ attribute: "title", hit })}</div>
              ${
                hit.updated_at_formatted && hit.format != "Élu·e"
                  ? `<div class="paris-search-modal-hit-update">Mise à jour le ${hit.updated_at_formatted}</div>`
                  : ""
              }
            </div>
            <div class="paris-search-modal-hit-image">
              ${hit.image ? `<img alt="" src="${hit.image}" loading="lazy">` : ""}
            </div>
          </a>
        `;
      },
    },
    sortir: {
      empty: `<p role="alert">Aucune sortie pour « {{ query }} »</p>`,
      item: (hit) => {
        const link = linkForMairie(hit, "path");
        const target = link.target ? `target="${link.target}"` : "";
        return `
          <a href="${link.href}" ${target} class="paris-search-modal-hit-link">
            <div class="paris-search-modal-hit-content">
              <div class="paris-search-modal-hit-title">${instantsearch.highlight({ attribute: "title", hit })}</div>
              ${
                hit.date_details
                  ? `<div class="paris-search-modal-hit-date">${icon("search/calendar", "Calendrier")}${
                      hit.date_details
                    }</div>`
                  : ""
              }
              ${
                !hit.date_details && hit.updated_at_formatted
                  ? `<div class="paris-search-modal-hit-update">Mise à jour le ${hit.updated_at_formatted}</div>`
                  : ""
              }
            </div>
            <div class="paris-search-modal-hit-image">
              ${hit.image ? `<img alt="" src="${hit.image}" loading="lazy">` : ""}
            </div>
          </a>
        `;
      },
    },
    activities: {
      empty: `<p role="alert">Aucune activité pour « {{ query }} »</p>`,
      item: (hit) => {
        const link = linkForMairie(hit, "path");
        const target = link.target ? `target="${link.target}"` : "";
        return `
          <a href="${link.href}" ${target} class="paris-search-modal-hit-link">
            <div class="paris-search-modal-hit-content">
              <div class="paris-search-modal-hit-title">${instantsearch.highlight({ attribute: "title", hit })} – ${
          hit.place_name
        }</div>
              ${
                hit.date_details
                  ? `<div class="paris-search-modal-hit-date">${icon("search/calendar", "Calendrier")}${
                      hit.date_details
                    }</div>`
                  : ""
              }
              ${
                !hit.date_details && hit.updated_at_formatted
                  ? `<div class="paris-search-modal-hit-update">Mise à jour le ${hit.updated_at_formatted}</div>`
                  : ""
              }
              ${
                hit.audience_details
                  ? `<div class="paris-search-modal-hit-audience">${icon("search/audience", "Public")}${
                      hit.audience_details
                    }</div>`
                  : ""
              }
            </div>
            <div class="paris-search-modal-hit-image">
              ${hit.image ? `<img alt="" src="${hit.image}" loading="lazy">` : ""}
            </div>
          </a>
        `;
      },
    },
    places: {
      empty: `<p role="alert">Aucun lieu ne correspond à « {{ query }} »</p>`,
      item: (hit) => {
        const link = linkForMairie(hit, "url");
        const target = link.target ? `target="${link.target}"` : "";
        return `
          <a href="${link.href}" ${target} class="paris-search-modal-hit-link">
            <div class="paris-search-modal-hit-content">
              <div class="paris-search-modal-hit-title is-place-name">${instantsearch.highlight({
                attribute: "name",
                hit,
              })}</div>
              ${
                hit.address_district || hit.address_city
                  ? `<div class="paris-search-modal-hit-place">${icon("search/minimarker", "Lieu")}<span>${
                      hit.address_district ? `Paris ${hit.address_district}` : hit.address_city
                    }</span></div>`
                  : ""
              }
              ${
                hit.open_details && hit.open_details.text
                  ? `<div class="paris-search-modal-hit-open-details is-${hit.open_details.color}">${hit.open_details.text}</div>`
                  : ""
              }
            </div>
            <div class="paris-search-modal-hit-image">
              ${hit.image ? `<img alt="" src="${hit.image}" loading="lazy">` : ""}
            </div>
          </a>
        `;
      },
    },
  },
  electeds: {
    empty: `<p role="alert">Aucun résultat ne correspond à votre recherche ou vos choix de filtres.</p>`,
    item: (hit) => `
      <div class="paris-elected-card">
        <div class="paris-elected-card-content">
          <a href="${hit.path}" class="paris-elected-card-name">${instantsearch.highlight({
      attribute: "title",
      hit,
    })}</a>
          <div class="paris-elected-card-position">${instantsearch.highlight({ attribute: "lead_text", hit })}</div>
          <div class="paris-elected-card-group">${hit.mandates.join(", ")} – ${hit.political_group}</div>
          <div class="paris-button is-red"><span class="paris-button-text">Voir la fiche</span></div>
        </div>
        <div class="paris-elected-card-image">
          ${hit.image ? `<img alt="" src="${hit.image}" loading="lazy">` : ""}
        </div>
      </div>`,
  },
  includedEvents: {
    empty: `<p role="alert">Aucun événement ne correspond à votre recherche ou vos choix de filtres.</p>`,
    item: (hit) => {
      const link = linkForMairie(hit, "path");
      const tags = [];
      if (hit.tags) {
        hit.tags.forEach((tag) => {
          const link = linkForGlobal(tag, "path");
          tags.push(`<a class="qfap--tag" href="${link.href}" target="${link.target}">
            <span class="qfap--tag-text">${tag.name}</span>
            <svg class="paris-icon paris-icon-qfap--tags-stroke" aria-hidden="true"><use xlink:href="#paris-icon-qfap--tags-stroke"></use></svg>
            <svg class="paris-icon paris-icon-qfap--tags-ring" aria-hidden="true"><use xlink:href="#paris-icon-qfap--tags-ring"></use></svg>
            </a>`);
        });
      }

      return `
        <div class="blocks--reference has-default-format${hit.number ? " has-number" : ""}"${
        hit.itemId ? ` id="${hit.itemId}"` : ""
      }${
        hit.number
          ? ` data-hit-number="${hit.number}" data-action="mouseenter->included-events#enterHit mouseleave->included-events#leaveHit"`
          : ""
      }>
          ${hit.number ? `<div class="blocks--reference-number">${hit.number}</div>` : ""}
          <div class="blocks--reference-header">
            <div class="blocks--reference-heading">
              <a class="blocks--reference-title blocks--reference-link" href="${link.href}" target="${link.target}">
                ${hit.title}
              </a>
              ${
                hit.place
                  ? `<div class="blocks--reference-address">${icon("search/minimarker", "Lieu")}<span>${
                      hit.place
                    }</span></div>`
                  : ""
              }
              ${
                hit.date_details
                  ? `<div class="blocks--reference-date">${icon("search/calendar", "Calendrier")}<span>${
                      hit.date_details
                    }</span></div>`
                  : ""
              }
              ${hit.tags ? `<div class="qfap--tags">${tags.join("")}</div>` : ""}
            </div>
            <div class="blocks--reference-image-wrapper">
              ${hit.image ? `<img alt="" class="blocks--reference-image" src="${hit.image}" loading="lazy">` : ""}
            </div>
          </div>
          <div class="blocks--reference-text">
            ${hit.lead_text || truncateStr(hit.content, 200)}
          </div>
          <div class="blocks--reference-link-label">${icon("qfap/arrow-right")}Voir l'événement</div>
        </div>
      `;
    },
  },
};
