export function rot13(str) {
  const re = new RegExp("[a-z]", "i");
  const min = "A".charCodeAt(0);
  const max = "Z".charCodeAt(0);
  const factor = 13;
  let result = "";
  str = str.toUpperCase();

  for (let i = 0; i < str.length; i++) {
    result += re.test(str[i])
      ? String.fromCharCode(((str.charCodeAt(i) - min + factor) % (max - min + 1)) + min)
      : str[i];
  }

  return result.toLowerCase();
}

export function checkIfEmailInString(text) {
  const allHiddenMails = document.querySelectorAll(".hidden-mail");
  allHiddenMails.forEach((m) => {
    m.classList.remove("hidden-mail");
  });
  const noScriptSpan = document.querySelectorAll(".no-script-span");
  noScriptSpan.forEach((m) => {
    m.classList.add("hidden-span");
  });

  const re =
    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})|([a-zA-Z\-0-9]+))/;
  return re.test(text);
}

export function copyText(txt, target, isLink) {
  const fromPlacesSidebar = target.classList.contains("sidebar-links-item-link");

  const now = new Date().getTime();
  const id = `links-copiedAlert-${now}`;
  const inputTemp = document.createElement("INPUT");
  inputTemp.setAttribute("id", "toCopy");
  inputTemp.setAttribute("type", "text");
  inputTemp.style.height = 0;
  document.body.appendChild(inputTemp);
  inputTemp.value = txt;
  const toCopy = document.querySelector("#toCopy");
  toCopy.select();
  document.execCommand("copy");
  toCopy.remove();
  const tempAlert = document.createElement("SPAN");
  tempAlert.setAttribute("id", id);
  tempAlert.setAttribute("class", "links-copied-alert-inline");
  tempAlert.innerText = "Adresse copiée dans votre presse papier";
  target.appendChild(tempAlert);
  tempAlert.style.top = `${target.offsetTop - 4}px`;
  tempAlert.style.left = `${target.offsetLeft}px`;
  if (fromPlacesSidebar) {
    tempAlert.setAttribute("class", "links-copied-alert-inline alert-inline-white from-places");
  }

  if (isLink && !fromPlacesSidebar) {
    tempAlert.style.transform = "translateY(-50%) translateX(-25%)";
  }

  setTimeout(() => {
    tempAlert.remove();
  }, 1500);
}

export function checkTarget(target, link) {
  if (!target || target === "false") {
    location.href = link;
    return;
  }

  const blank = target.includes("blank");
  if (blank) {
    window.open(link, "_blank");
  } else {
    location.href = link;
  }
}

export function linkTo(e, isLink) {
  e.preventDefault();
  const link = e.target.closest("a").getAttribute("href");
  const target = e.target.closest("a").getAttribute("target");

  if (link.includes("mailto")) {
    copyText(link.replace("mailto:", ""), e.target.closest("a"), isLink);
  } else {
    checkTarget(target, link);
  }
}

export function listenLinks(container, isLink) {
  if (isLink) {
    container.addEventListener("click", (e) => {
      linkTo(e, true);
    });
  } else {
    const links = container.querySelectorAll("a");

    if (links) {
      links.forEach((link) => {
        link.addEventListener("click", (e) => {
          linkTo(e);
        });
      });
    }
  }
}

export function decodeMails(hasTarget, target, includeHref, promise) {
  const html = hasTarget ? target.innerHTML : "";
  const containsMail = checkIfEmailInString(html);

  if (includeHref) {
    decodeHref(target);
  }

  if (containsMail) {
    const emailRegex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
    const result = html.replace(emailRegex, (mail) => {
      return rot13(mail);
    });

    if (promise) {
      return {
        result,
        target,
      };
    }

    target.innerHTML = result;
    listenLinks(target);
  }
}

export function decodeHref(target) {
  const href = target.getAttribute("href");
  if (href.includes("mailto")) {
    const url = href.replace("mailto:", "");
    target.setAttribute("href", `mailto:${rot13(url).toLowerCase()}`);
    listenLinks(target, true);
  }
}
