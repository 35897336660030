import L from "leaflet";
import icon from "../../../../assets/javascript/utils/icon_helper";

const locationControl = L.Control.extend({
  onAdd: function(map) {
    let div = L.DomUtil.create('div', 'leaflet-bar');
    let button = L.DomUtil.create('a', 'paris-search-hits-map-control paris-search-hits-map-control-location', div);
    button.innerHTML = icon("search/location");

    // prevent "double click" issues, inspired from
    // https://github.com/Leaflet/Leaflet/blob/main/src/control/Control.Zoom.js
    L.DomEvent.disableClickPropagation(button);
		L.DomEvent.on(button, 'click', L.DomEvent.stop);
		L.DomEvent.on(button, 'click', () => {
      map.locate({ setView: true, maxZoom: 16 });
    }, this);
		L.DomEvent.on(button, 'click', this._refocusOnMap, this);
    return div;
  },
});

const sizeControl = L.Control.extend({
  onAdd: function(map) {
    let div = L.DomUtil.create('div', 'leaflet-bar');
    let button = L.DomUtil.create('a', 'paris-search-hits-map-control paris-search-hits-map-control-size', div);
    button.innerHTML = `${icon("search/size")} <span class="paris-search-hits-map-control-text">Agrandir</span><span class="paris-search-hits-map-control-text" hidden>Réduire</span>`;

    const texts = button.querySelectorAll(".paris-search-hits-map-control-text");

    // prevent "double click" issues, inspired from
    // https://github.com/Leaflet/Leaflet/blob/main/src/control/Control.Zoom.js
    L.DomEvent.disableClickPropagation(button);
    L.DomEvent.on(button, 'click', L.DomEvent.stop);
		L.DomEvent.on(button, 'click', (e) => {
      document.querySelector(".paris-search").classList.toggle("has-large-map");
      texts.forEach(t => {
        t.hidden = !t.hidden;
      });
      map.invalidateSize();
    }, this);
		L.DomEvent.on(button, 'click', this._refocusOnMap, this);
    return div;
  },
});

export {
  locationControl,
  sizeControl,
};
