import "./accessibility_dropdown_component.scss";

import { Controller as BaseController } from "stimulus";
import { useClickOutside } from "stimulus-use";

export class Controller extends BaseController {
  static targets = ["toggleButton", "menu", "closeButton"];

  initialize() {
    this.keydownHandler = this.keydown.bind(this);
  }

  connect() {
    this.opened = false;
    useClickOutside(this);
  }

  open() {
    if (this.opened) return;
    this.opened = true;
    this.element.classList.add("is-open");
    this.toggleButtonTarget.setAttribute("aria-expanded", "true");
    this.enableEsc();
    this.closeButtonTarget.focus();
  }

  close() {
    if (!this.opened) return;
    this.opened = false;
    this.element.classList.remove("is-open");
    this.disableEsc();
    this.toggleButtonTarget.setAttribute("aria-expanded", "false");
    this.toggleButtonTarget.focus();
  }

  toggle() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  clickOutside(_) {
    this.close();
  }

  enableEsc() {
    window.addEventListener("keydown", this.keydownHandler);
  }

  disableEsc() {
    window.removeEventListener("keydown", this.keydownHandler);
  }

  keydown(e) {
    switch (e.code) {
      case "Escape":
        this.close();
    }
  }
}
