import { connectRefinementList } from "instantsearch.js/es/connectors";
import TomSelect from "tom-select";
import "tom-select/dist/scss/tom-select";

const renderRefinementList = (renderOptions, isFirstRender) => {
  const { items, refine, widgetParams } = renderOptions;
  const { container } = widgetParams;
  const currentRefinement =
    renderOptions.instantSearchInstance.helper.state.disjunctiveFacetsRefinements.searchable_place;

  // FIRST RENDERING ------------------------------------------------
  if (isFirstRender) {
    const fragment = document.createRange().createContextualFragment(`
        <label class="ais-DateSelector-label-title" for="place-selector">${widgetParams.title || "Lieu"}</label>
        <select id="place-selector" class="form-search-input"></select>`);
    container.appendChild(fragment);
    return;
  }

  // OTHER RENDERING ------------------------------------------------
  const selectEl = document.querySelector("#place-selector");

  if (selectEl.classList.contains("tomselected")) {
    window.placeSelector.settings.placeholder =
      items.length === 0 ? "Pas de lieu disponible..." : "Chercher un Lieu...";
    window.placeSelector.inputState();
    window.placeSelector.clearOptions();
    window.placeSelector.addOptions(
      items.map((item) => {
        const placeInfo = item.label.split("||");
        const placeAddress = placeInfo[2];
        const place = {
          name: placeInfo[0],
          address: placeAddress,
        };
        return {
          value: item.value,
          text: place,
        };
      })
    );
    return;
  }

  window.placeSelector = new TomSelect(document.querySelector("#place-selector"), {
    plugins: {
      input_autogrow: {},
      remove_button: { title: "Retirer" },
    },
    placeholder: items.length === 0 ? "Aucun lieu disponible..." : "Chercher un Lieu...",
    hidePlaceholder: true,
    items: currentRefinement,
    optionClass: "option",
    itemClass: "item",
    options: items.map((item) => {
      const placeInfo = item.label.split("||");
      const placeAddress = placeInfo[2];
      const place = {
        name: placeInfo[0],
        address: placeAddress,
      };
      return {
        value: item.value,
        text: place,
      };
    }),
    closeAfterSelect: true,
    render: {
      option: function (data, escape) {
        return `
        <div>
          <strong>${escape(data.text.name)}</strong><br>
          ${escape(data.text.address)}
        </div>
        `;
      },
      item: function (data, escape) {
        return `<div class ="place-item">${escape(data.text.name)}</div>`;
      },
     
      no_results: function(data,escape){
        return '<div class="no-results">Aucun lieu trouvé pour "'+escape(data.input)+'"</div>';
      },
    },
  });

  window.placeSelector.on("item_add", (value) => {
    refine(value);
  });
  window.placeSelector.on("item_remove", (value) => refine(value));
};

export default connectRefinementList(renderRefinementList);
