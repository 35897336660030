const widget = widgetOpts => {
    const fragment = document.createRange().createContextualFragment(`
      <label class="ais-DateSelector-label" for="">${widgetOpts.title || "Date"}</label>
      <input type="date" id="" class="ais-DateSelector-input" />
    `);
    const input = fragment.querySelector("input");
    const attr = widgetOpts.attribute;
  
    return {
      init(initOpts) {
        widgetOpts.container.appendChild(fragment);
  
        input.addEventListener("input", e => {
          const value = e.target.value;
  
          if (value) {
            const date = new Date(value);
            date.setHours(0, 0, 0, 0);
            const timestamp = date.getTime() / 1000;
  
            if (widgetOpts.incompatibleAttribute) {
              initOpts.helper.clearRefinements(widgetOpts.incompatibleAttribute)
            }
  
            initOpts.helper
              .clearRefinements(attr)
              .addNumericRefinement(attr, "=", timestamp)
              .search();
          } else {
  
            if (widgetOpts.incompatibleAttribute) {
              initOpts.helper.clearRefinements(widgetOpts.incompatibleAttribute)
            }
  
            initOpts.helper
              .clearRefinements(attr)
              .search();
          }
        });
      },
      render(renderOpts) {
        const isRefined = renderOpts.helper.hasRefinements(attr);
  
        if (!isRefined) {
          input.value = "";
        }
      },
      dispose() {
        const rootEl = input.closest(".ais-DateSelector-label");
        if (rootEl) {
          rootEl.parentNode.removeChild(rootEl);
        }
      }
    };
  };
  
  export default widget;