import "./frame_component.scss";

import { Controller as BaseController } from "stimulus";
import { decodeMails } from "../../../assets/javascript/utils/mailsHelper";

export class Controller extends BaseController {
  static targets = ["textContainer", "titleContainer"];

  connect() {
    this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
    decodeMails(this.hasTextContainerTarget, this.textContainerTarget);
    if (this.hasTitleContainerTarget) {
      decodeMails(this.hasTitleContainerTarget, this.titleContainerTarget);
      this.anchorController?.checkForMultipleLinktags(this.titleContainerTarget);
    }

    if (this.hasTextContainerTarget) {
      this.anchorController?.checkForMultipleLinktags(this.textContainerTarget);
    }
  }
}
