import { Controller } from "stimulus";

import { getElementTopYPosition } from "../../util/helpers";

export default class extends Controller {
  static targets = [];

  initialize() {
    this.navBarHeight = 0;
    this.marginScroll = 10;
  }

  connect() {
    if (window.location.hash) setTimeout(() => this.scrollToAnchor(window.location.hash), 100);

    // calculate header height
    const headerElem = document.querySelector(".paris-header");
    if (headerElem) {
      this.navBarHeight = headerElem.offsetHeight;
    }
  }

  scrollToAnchor(hash) {
    if (typeof hash === "string" && hash.startsWith("#")) {
      hash = hash.substring(1);
    }

    let anchorElem = document.getElementById(hash);

    if (!anchorElem) {
      return;
    }

    // display panel in district or agenda component
    if ((this.districtController = this._getControllerForIdentifierContainingElem("districts", anchorElem))) {
      this.districtController.displayDistrict(hash);
      anchorElem = this.districtController.element;
    } else if ((this.agendaController = this._getControllerForIdentifierContainingElem("giants-agenda", anchorElem))) {
      this.agendaController.displayFilter(hash);
      anchorElem = this.agendaController.element;
    }

    // update hash in url
    window.history.pushState({}, hash, "#" + hash);

    // scroll to anchor element
    window.scrollTo({
      top: getElementTopYPosition(anchorElem) - this.navBarHeight - this.marginScroll,
      behavior: "smooth"
    });

    // focus on anchor element
    anchorElem.focus();
  }

  checkForMultipleLinktags(container) {
    const links = container.querySelectorAll("a");
    if (links.length > 0) {
      [...links].forEach(link => {
        const href = link.getAttribute("href");
        if (href.startsWith("#")) {
          link.onclick = e => {
            e.preventDefault();
            this.scrollToAnchor(href);
          };
        }
      });
    }
  }

  // Get controller associated to element with the specific identifier
  _getControllerForIdentifierContainingElem(identifier, element) {
    if (!element || typeof element.closest !== "function") {
      throw new Error("Missing DOM element");
    }

    const controllerElement = element.closest(`[${this.application.schema.controllerAttribute}~="${identifier}"]`);
    return this.application.getControllerForElementAndIdentifier(controllerElement, identifier);
  }
}
