import "./tabs_component.scss";

import Rails from "@rails/ujs";
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["button", "panel", "showMoreButton"];

  initialize() {
    this.mediaQueryList = window.matchMedia("(max-width: 799px)");
  }

  connect() {
    this.currentButton = this.buttonTargets.find((button) => button.classList.contains("is-current"));
    this.currentPanel = this.panelTargets.find((panel) => panel.classList.contains("is-current"));

    // if (this.isSmallAndMedium && this.itemTargets.length > 5) {
    //   this.connectShowMore();
    // }

    console.debug('connect', this.currentButton, this.currentPanel, this.currentUrl)
    this.loadCurrentTab()
  }

  // connectShowMore() {
  //   this.itemTargets.forEach((item, index) => {
  //     if (index >= 5) {
  //       item.classList.add("is-hidden");
  //     }
  //   });
  //   this.element.insertAdjacentHTML(
  //     "beforeend",
  //     `<button class="qfap--filters-show-more" data-action="qfap--filters#showMore" data-target="qfap--filters.showMoreButton">Voir plus de tags</button>`
  //   );
  // }

  loadCurrentTab() {
    console.debug('loadCurrentTab')
    this.currentUrl = this.currentButton.dataset.reloadContentUrl
    this.loadContent(this.currentUrl)
  }

  loadContent(url) {
    Rails.ajax({
      type: "GET",
      url: url,
      success: (response, _status, xhr) => {
        console.debug('succes!', response)
        // if (xhr.status === 204) {
        //   // We reached the end of the content, stop loading more
        //   this.stopLoadingMore();
        // } else {
        //   if (this.shouldReplaceContent) {
        //     this.shouldReplaceContent = false;
        //     this.replaceContent(response);
        //   } else {
        //     this.appendContent(response);
        //   }
        //   this.updateControls();
        // }

        // Read response headers
        // this.setContentUrl(xhr.getResponseHeader("X-Next-Content-Url"));
        this.setCurrentContent(response)
      },
      error: (_response, _status, xhr) => {
        console.error(xhr.response);
      },
    });
  }

  setCurrentContent(response) {
    console.debug('setCurrentContent', response)
    this.currentPanel.insertAdjacentHTML("beforeend", response.body.innerHTML);
  }

  toggleItem(e) {
    e.preventDefault();

    if (this.currentButton == e.target) return;


    this.currentButton = e.target;
    this.currentPanel = e.target;

    this.buttonTargets.forEach((button) => {
      if (button == this.currentButton) {
        button.classList.toggle("is-current", true);
        button.setAttribute('aria-selected', 'true');
        button.removeAttribute('tabindex');
        // TODO:
        // this.tabpanels[i].classList.remove('is-hidden');
      } else {
        button.classList.toggle("is-current", false);
        button.setAttribute('aria-selected', 'false');
        button.tabIndex = -1;
        // TODO:
        // this.tabpanels[i].classList.add('is-hidden');
      }
    });
    this.panelTargets.forEach((panel) => {
      panel.classList.toggle("is-current", panel == this.currentPanel);
    });
  }

  // showMore(e) {
  //   e.preventDefault();

  //   this.itemTargets.forEach((item) => {
  //     item.classList.remove("is-hidden");
  //   });

  //   this.showMoreButtonTarget.remove();
  // }

  get isSmallAndMedium() {
    return this.mediaQueryList.matches;
  }
}
