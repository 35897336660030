import "./panels_component.scss";
import Tablist from "@accede-web/tablist";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  connect() {
    this.tablist = new Tablist(this.element);
    this.tablist.mount();
  }
}
