import { decodeMails } from "../../../assets/javascript/utils/mailsHelper";
import "./text_component.scss";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["textContainer"];

  connect() {
    if (!this.hasTextContainerTarget) return;

    this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");

    this.textContainerTargets.forEach((textTarget) => {
      decodeMails(true, textTarget);
      this.anchorController.checkForMultipleLinktags(textTarget);
    });
  }
}
