import "./regular_schedules_component.scss";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["content", "mainTitle", "option", "selectorContent", "prompt", "tab"];

  connect() {
    this._listenClickOutside();
  }

  selectPeriod(e) {
    if (e.target.classList.contains("disabled")) return;
    const index = e.target.dataset.index;
    this.mainTitleTarget.innerText = e.target.innerText;
    this._toggleOptions(index);
  }

  toggleSelector() {
    const isHidden = this.selectorContentTarget.classList.contains("hidden");
    this.selectorContentTarget.classList.toggle("hidden", !isHidden);
    this.promptTarget.querySelector(".paris-icon").classList.toggle("up", isHidden);
  }

  _toggleOptions(index) {
    this._hideAll();
    this.optionTargets[index].classList.add("disabled");
    this.promptTarget.click();
    this.tabTargets[index].classList.remove("hidden");
  }

  _listenClickOutside() {
    if (!this.hasPromptTarget) return;
    document.addEventListener("click", (e) => {
      const withinBoundaries =
        e.composedPath().includes(this.promptTarget) || e.composedPath().includes(this.selectorContentTarget);
      const isHidden = this.selectorContentTarget.classList.contains("hidden");

      if (!withinBoundaries && !isHidden) this.promptTarget.click();
    });
  }

  _hideAll() {
    this.tabTargets.forEach((item) => item.classList.add("hidden"));
    this.optionTargets.forEach((item) => item.classList.remove("disabled"));
  }
}
