import "./toc_component.scss";
import { getElementTopYPosition } from "util/helpers";
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {

  static targets = ["heading", "content", "nav", "tocEntry"];

  initialize() {
    this.mediaQueryList = window.matchMedia("(min-width:800px)");
    this.isHovered = false;
  }

  connect() {
    if (this.isSmall) {
      this.initDialog();
      this.initKeyboard();
      this.initFocus();
    } else {
      // The toc is closed by default and will be opened if not on mobile
      this.openDialog();
    }
  }

  initDialog() {
    this.element.setAttribute("role", "dialog");
    this.element.setAttribute("aria-label", "Sommaire");
    this.element.setAttribute("aria-modal", "true");
  }

  openDialog() {
    this.element.classList.toggle("is-open");
    this.initTitles();
    this.initScroll();
    this.unfold();
  }

  initKeyboard() {
    document.onkeydown = (e) => {
      const event = e || window.event;

      if (event.key === "Escape") {
        this.toggleTocContent();
      }
    };
  }

  disposeKeyboard() {
    document.onkeydown = null;
  }

  initFocus() {
    document.addEventListener("focus", this.trapFocus.bind(this), true);
  }

  disposeFocus() {
    document.removeEventListener("focus", this.trapFocus.bind(this), true);

    if (this.opener) {
      this.opener.focus();
      this.opener = null;
    }
  }

  trapFocus(e) {
    if (this.element.contains(e.target)) {
      this.lastFocus = e.target;
      return;
    }

    if (this.lastFocus === this.lastFocusableElement) {
      e.preventDefault();
      this.firstFocusableElement.focus();
    } else if (this.lastFocus === this.firstFocusableElement) {
      e.preventDefault();
      this.lastFocusableElement.focus();
    }
  }

  initScroll() {
    const posUpdate = () => {
      this.updateTocHeight();
    };

    window.addEventListener("scroll", posUpdate);
  }

  updateTocHeight() {
    const winPosition = window.scrollY;
    const navBarHeight = document.querySelector(".paris-header").offsetHeight;
    this.handleBulletPointsActivation(winPosition, navBarHeight);
    this.handleJsStuckAndJsFold(winPosition, navBarHeight);
  }

  initTitles() {
    const allTitles = document.querySelectorAll(".in-toc");

    this.titles = Array.from(allTitles).filter((title) => {
      let parentDiv = title.closest(".block");
      return parentDiv && !parentDiv.classList.contains("d-none");
    });

    this.visibleTitlesCount = this.titles.length;
  }

  handleBulletPointsActivation(windowYposition, navBarHeight) {
    let elementsBorders = [];
    this.titles.forEach((title) => {
      elementsBorders.push(getElementTopYPosition(title));
    });
    elementsBorders.push(this.getElementBottomYPosition(document.querySelector(".blocks")));
    // Ex for 3 titles : elementsBorders = [1382, 2407, 2892, 3073];

    let elements = [];
    for (let i = 0; i < this.visibleTitlesCount; i++) {
      elements.push({ start: elementsBorders[i], end: elementsBorders[i + 1] - 1 });
    }
    // Ex: elements = [{start: 1382 , end: 2406}, {start: 2407 , end: 2891}, {start: 2892 , end: 3073}]

    elements.forEach((element, index) => {
      let htmlElement = document.querySelector(`.toc-item-${index}`);
      const windowPositionWithOffset = windowYposition + navBarHeight + 21;
      const isBetween = this.isBetween(windowPositionWithOffset, element.start, element.end);
      htmlElement.classList.toggle("active", isBetween);
    });
  }

  handleJsStuckAndJsFold(windowYposition, navBarHeight) {
    if (!this.visibleTitlesCount) {
      this.contentTarget.classList.remove("js-stuck");
      return;
    }

    const tocHeadingYPosition = getElementTopYPosition(this.headingTarget);
    const firstTitleYPosition = getElementTopYPosition(this.titles[0]);

    const handleClasses = (elementCompared, classInvolved) => {
      const isClassPresent = this.contentTarget.classList.contains(classInvolved);
      const shouldAddClass = windowYposition + navBarHeight > elementCompared;
      const shouldRemoveClass = windowYposition + navBarHeight < elementCompared;

      if (!isClassPresent && shouldAddClass) {
        this.contentTarget.classList.add(classInvolved);
        if (classInvolved === "js-fold") this.fold();
      } else if (isClassPresent && shouldRemoveClass) {
        this.contentTarget.classList.remove(classInvolved);
        if (classInvolved === "js-fold") this.unfold();
      }
    };

    handleClasses(tocHeadingYPosition, "js-stuck");
    handleClasses(firstTitleYPosition, "js-fold");
  }

  isBetween(elementToCheck, minValue, maxValue) {
    return (elementToCheck - minValue) * (elementToCheck - maxValue) <= 0;
  }

  toggleTocContent() {
    if (!this.isSmall) return;

    this.storeOpener();
    this.element.classList.toggle("is-open");

    document.body.classList.toggle("has-disabled-scroll", this.isOpen);

    if (this.isOpen) {
      // Scroll so the toc is at the top of the page
      window.scrollTo({
        top: getElementTopYPosition(this.headingTarget) - 30,
        behavior: "smooth",
      });
      this.initKeyboard();
      this.initFocus();
    } else {
      this.disposeKeyboard();
      this.disposeFocus();
    }
  }

  handleMouseEnter() {
    this.isHovered = true;
    this.unfold();
  }

  handleMouseLeave() {
    this.isHovered = false;
    this.fold();
  }

  fold() {
    if (this.contentTarget.classList.contains("js-fold") && !this.isHovered) {
      this.tocEntryTargets.forEach((entry) => {
        entry.style.height = null;
      });
    }
  }

  unfold() {
    this.tocEntryTargets.forEach((entry) => {
      entry.style.height = `${entry.scrollHeight}px`;
    });
  }

  handleTocItemClick(event) {
    event.preventDefault();

    if (this.isSmall) {
      this.toggleTocContent();
    }

    const targetedContentUrl = event.currentTarget.getAttribute("href");
    this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
    this.anchorController.scrollToAnchor(targetedContentUrl);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  getElementBottomYPosition(element) {
    let elementHeight = element.getBoundingClientRect().height;
    return getElementTopYPosition(element) + elementHeight;
  }

  storeOpener() {
    // store the current focused element before focusing the layer
    this.opener = this.opener || this.activeElement || document.activeElement;
  }

  get isOpen() {
    return this.element.classList.contains("is-open");
  }

  get isSmall() {
    return !this.mediaQueryList.matches;
  }

  get focusableElements() {
    return this.element.querySelectorAll(
      "a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled])"
    );
  }

  get firstFocusableElement() {
    const focusableElements = this.focusableElements;
    return focusableElements[0];
  }

  get lastFocusableElement() {
    const focusableElements = this.focusableElements;
    return focusableElements[focusableElements.length - 1];
  }
}
