import { currentRefinements } from "instantsearch.js/es/widgets";
import hitsOnMap from "./map/hits_on_map";

const commonWidgets = {
  map: {
    target: "map",
    mobileTarget: "miniMap",
    widget: container =>
      hitsOnMap({
        resizable: true,
        container,
      }),
  },
};

export default {
  everything: [],
  info: [],
  sortir: [
    {
      target: "refinements",
      widget: container =>
        currentRefinements({
          includedAttributes: ["full_districts", "universe"],
          container
        }),
    },
    commonWidgets.map,
  ],
  activities: [
    {
      target: "refinements",
      widget: container =>
        currentRefinements({
          includedAttributes: ["discipline", "full_districts"],
          container
        }),
    },
    commonWidgets.map,
  ],
  places: [
    {
      target: "refinements",
      widget: container =>
        currentRefinements({
          includedAttributes: ["category_names", "full_districts"],
          container
        }),
    },
    commonWidgets.map,
  ],
  electeds: [],
};
