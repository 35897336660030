const widget = widgetOpts => {
  return {
    init(initOpts) {
      const { attribute, value } = widgetOpts;
      const { helper } = initOpts;

      helper.state.facets.push(attribute);
      helper.addFacetRefinement(attribute, value);
    },
  };
};

export default widget;
