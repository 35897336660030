import "./notice_component.scss";
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["archived"];

  connect() { 
    if (this.element.dataset.archived === "true") {
      this._countText();
    }
  }

  // ACTIONS

  close() {
    this.element.classList.add("fade");
    setTimeout(() => this.remove(), 500);
  }

  remove() {
    this.element.parentNode.removeChild(this.element);
  }

  _countText() { 
    const title = document.querySelector(".is-level-1");
    if (title.textContent.length > 60) {
      this.element.style.position = "relative";      
    }
  }
}
