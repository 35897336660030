/* global gon */
import _isPlainObject from "lodash/isPlainObject";
import _isString from "lodash/isString";
import _map from "lodash/map";

////////////
// STRING //
////////////

export function slugify(string) {
  if (!_isString(string)) {
    return "";
  }

  /* eslint-disable */
  // prettier-ignore
  const specialChars = {
    "à":"a","á":"a","ä":"a","â":"a","æ":"ae","å":"a",
    "é":"e","è":"e","ë":"e","ê":"e",
    "í":"i","ì":"i","ï":"i","î":"i","ı":"i",
    "ó":"o","ò":"o","ö":"o","ô":"o","õ":"o","ø":"o","œ":"oe",
    "ú":"u","ù":"u","ü":"u","û":"u",
    "ÿ":"y",
    "ç":"c","ş":"s",
    "ń":"n","ǹ":"n","ñ":"n",
    "ß":"s",
    "ğ":"g",
    "а":"a","б":"b","в":"v","г":"g","д":"d","е":"e","ё":"yo","ж":"zh","з":"z","и":"i","й":"j","к":"k",
    "л":"l","м":"m","н":"n","о":"o","п":"p","р":"r","с":"s","т":"t","у":"u","ф":"f","х":"h","ц":"c",
    "ч":"ch","ш":"sh","щ":"sh","ъ":"","ы":"y","ь":"","э":"e","ю":"yu","я":"ya",
    "'":"-","’":"-","·":"-","/":"-","_":"-",",":"-",":":"-",";":"-",".":"-",
    "&":"-et-","€":"-euro-",
  };
  /* eslint-enable */

  return string
    .toLowerCase()
    .replace(/\s+/g, "-") // replace spaces with -
    .replace(/./g, (target) => specialChars[target] || target) // replace special characters using the hash map
    .replace(/[^\w-]+/g, "") // remove all non-word chars
    .replace(/--+/g, "-") // replace multiple - with single -
    .replace(/^-+/, "") // trim - from start of text
    .replace(/-+$/, "") // trim - from end of text
    .substring(0, 100); // We need a length of 101 as we will remove the first character '#'
}

export function isEmail(string) {
  // source: http://emailregex.com/
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})|([a-zA-Z\-0-9]+))$/;
  return re.test(string);
}

export function formatMailTo(string) {
  if (!_isString(string)) {
    return "";
  }

  if (!isEmail(string)) {
    return string;
  }

  return "mailto:" + string;
}

export function reFormatEndOfString(str) {
  const index = str.lastIndexOf(" ...");
  if (index > 0) {
    return `${str.substr(0, index)}...`;
  }

  return str;
}

export function strip(html) {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export function capitalizeTxt(txt) {
  const title = txt.substring(0, txt.lastIndexOf(".")).replace(/_/gi, " ").replace(/-/gi, " ");
  return `${title[0].toUpperCase()}${title.substring(1)}`;
}

/////////
// GON //
/////////

export function host() {
  return gon.host;
}

export function matomo() {
  return gon.matomo;
}

export function displayComments() {
  return gon.display_comments;
}

export function getIdSite() {
  return new Promise((resolve) => {
    resolve(gon.idsite);
  });
}

export function fogUrl(path) {
  return gon.global.fog.endpoint + gon.global.fog.bucket + "/" + path;
}

export function currentUserFullName() {
  return gon.currentUser.full_name;
}

export function formatPath(path) {
  return path.includes("http") ? path : fogUrl(path);
}

//////////
// FILE //
//////////

export function hasExtension(fileName, exts) {
  return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(fileName);
}

export function fileSizeMO(number, fromMediaController) {
  if (!fromMediaController) return (number / 1048576).toFixed(1);
  if (number < 1000000) return Math.floor(number / 1000).toFixed(1);
  else return Math.floor(number / 1000000).toFixed(1);
}

export function createMarkup(markup) {
  return { __html: markup };
}

export function sanitizeFilename(filename) {
  return filename.replace(/[/\\?%*:|"<>]/g, "-");
}

export function humanFileSize(size) {
  if (size < 1024) return size + " B";
  const i = Math.floor(Math.log(size) / Math.log(1024));
  let num = size / Math.pow(1024, i);
  const round = Math.round(num);
  num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
  return `${num} ${"KMGTPEZY"[i - 1]}B`;
}

export function removeExtensionFromName(fileName) {
  return fileName.split(".").slice(0, -1).join(".");
}

function preventDefaults(e) {
  e.preventDefault();
  e.stopPropagation();
}

function dropFile(e) {
  return new Promise((resolve) => {
    const dt = e.dataTransfer;
    const files = dt.files;
    resolve(files);
  });
}

function highligth(e, shouldHighlight, target) {
  if (shouldHighlight) target.classList.add("highlight");
  else target.classList.remove("highlight");
}

export function listenDropZone(target, ctrl) {
  ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
    target.addEventListener(eventName, (e) => preventDefaults(e), false);
  });

  target.addEventListener("drop", (e) => dropFile(e).then((file) => ctrl.goTo(e, file)), false);

  ["dragenter", "dragover"].forEach((eventName) => {
    target.addEventListener(eventName, (e) => highligth(e, true, target), false);
  });

  ["dragleave", "drop"].forEach((eventName) => {
    target.addEventListener(eventName, (e) => highligth(e, false, target), false);
  });
}

export function checkAllowedFormat(files) {
  return new Promise((resolve, reject) => {
    if (["image/jpeg", "image/png", "image/gif"].includes(files[0].type)) resolve();
    else reject();
  });
}

/////////
// URL //
/////////

export function parseUrl(url) {
  // The easy way to parse an URL, is to create <a> element.
  // @see: https://gist.github.com/jlong/2428561
  const urlParser = document.createElement("a");
  urlParser.href = url;
  return urlParser;
}

// /bo/pages?kind=article&order_by%5Battribute%5D=id&order_by%5Bdirection%5D=asc&array[]=one&array[]=two#hash
// to
// { kind: "article", order_by: { attribute: "id", direction: "asc" }, array: ["one", "two"] }
export function parseUrlSearch(url) {
  if (!_isString(url) || url.length == 0) {
    return {};
  }

  const searchIndex = url.indexOf("?") + 1;
  const hashIndex = url.indexOf("#") > searchIndex ? url.indexOf("#") : url.length;
  const queries = url.slice(searchIndex, hashIndex).split("&");

  return queries.reduce((params, query) => {
    let [key, value] = query.split("=").map((str) => {
      return decodeURIComponent(str);
    });
    const arrayQuery = key.match(/\[.*\]$/g);

    if (arrayQuery) {
      key = key.replace(arrayQuery, "");
      const valueKey = arrayQuery[0].slice(1, -1);

      if (valueKey) {
        params[key] = params[key] || {};
        params[key][valueKey] = value;
      } else {
        params[key] = params[key] || [];
        params[key].push(value);
      }
    } else {
      params[key] = value;
    }

    return params;
  }, {});
}

// { kind: "article", order_by: { attribute: "id", direction: "asc" }, array: ["one", "two"] }
// to
// kind=article&order_by%5Battribute%5D=id&order_by%5Bdirection%5D=asc&array%5B%5D=one&array%5B%5D=two
export function stringifyUrlSearch(params) {
  return _map(params, (value, key) => {
    if (Array.isArray(value)) {
      return value
        .map((v) => {
          return encodeURIComponent(key + "[]") + "=" + encodeURIComponent(v);
        })
        .join("&");
    } else if (_isPlainObject(value)) {
      return _map(value, (v, k) => {
        return encodeURIComponent(key + "[" + k + "]") + "=" + encodeURIComponent(v);
      }).join("&");
    }

    return encodeURIComponent(key) + "=" + encodeURIComponent(value);
  }).join("&");
}

export const isExternalUrl = (url) => {
  if (!_isString(url) || url.startsWith("mailto:")) {
    return false;
  }
  const regexp = new RegExp("^(www.)?" + window.location.hostname + "$");
  return !regexp.test(parseUrl(url).hostname);
};

export function isValidURL(url) {
  // source: http://urlregex.com/
  // let re = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
  let re = /^(https?:\/\/|#|mailto:).+/;

  return re.test(url);
}

////////////
// SCROLL //
////////////

export function scrollToTop(el) {
  const element = el ? el : window;
  element.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export const getElementTopYPosition = (element) => {
  return element.getBoundingClientRect().top + document.documentElement.scrollTop;
};

////////////
// OTHERS //
////////////

export const removeOnUnloadWarning = () => {
  window.onbeforeunload = null;
};

export const dynamicSort = (property) => {
  let sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  return (a, b) => {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    }

    return a[property].localeCompare(b[property]);
  };
};

export function passCsrfToken(document, axios) {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = _csrfToken(document);
}

function _csrfToken(document) {
  return document.querySelector('[name="csrf-token"]') ? document.querySelector('[name="csrf-token"]').content : "";
}
export function escapeHtml(string) {
  const entityMap = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
    "/": "&#x2F;",
    "`": "&#x60;",
    "=": "&#x3D;",
  };
  return String(string).replace(/[&<>"'`=/]/g, function (s) {
    return entityMap[s];
  });
}

export function getCaretIndex(element) {
  if (typeof window.getSelection === "undefined") return 0;

  let position = 0;
  const selection = window.getSelection();

  if (selection.rangeCount !== 0) {
    const range = window.getSelection().getRangeAt(0);
    const currentRange = range.cloneRange();
    currentRange.selectNodeContents(element);
    currentRange.setEnd(range.endContainer, range.endOffset);
    position = currentRange.toString().length;
  }

  return position;
}

export function placeCaret(elem, position) {
  let selection;
  elem.focus();
  selection = window.getSelection();

  const allowedLimit = elem.firstChild.textContent.length;

  if (position > allowedLimit) {
    elem = elem.lastChild;
    position = position - allowedLimit;
  }
  selection.collapse(elem.firstChild, position);
}

//////////////////////////
// COOKIES & CS HELPERS //
//////////////////////////

export const pageType = (pageType) => {
  const pathname = window.location.pathname;
  switch (pageType) {
    case "pages":
      if (pathname === "/") {
        return "Home";
      }

      if (pathname === "/quefaire/") {
        return "Home QFAP";
      }

      return "Article";
    case "giants":
      return "Grand format";
    case "themes":
      return "Thème";
    case "portfolios":
      return "13 Photos";
    case "electeds":
      if (pathname === "/elu-e-s/adjoint-e-s-a-la-maire") {
        return "Mandat";
      }

      return "Fiche élu·e";
    case "equipments":
      return "Équipements";
    case "search":
      return "Recherche";
    case "polling":
      return "Élections";
    case "tags":
      return "Tag QFAP";
    case "selections":
      return "Sélection hebdo QFAP";
    default:
      return "Article";
  }
};

export const humanized_status = (status) => {
  switch (status) {
    case "published":
      return "Publié";
    case "archived":
      return "Publié et archivé";
    default:
      return "draft";
  }
};

export const preventSubmit = (element) => {
  element.addEventListener("keypress", (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  });
};
