import "./heading_component.scss";
import "./images/electeds.jpg";

import { Controller as BaseController } from "stimulus";
import LazyLoad from "vanilla-lazyload";

export class Controller extends BaseController {
  static targets = ["title", "image", "imageContainer", "footer"];

  initialize() {
    this.timeout = false;
    this.delay = 200;
    this.keydownHandler = this.keydown.bind(this);
  }

  connect() {
    this.initLazyLoad();
  }

  initLazyLoad() {
    this.lazyLoadInstance = new LazyLoad(
      {
        callback_loaded: (el) => this.handleTitle(el),
      },
      this.imageTargets
    );
  }

  handleTitle() {
    if (!this.hasTitleTarget || window.matchMedia("(max-width:800px)").matches) return;

    const elementHeight = this.element.clientHeight;
    const titleHeight = this.titleTarget.clientHeight;
    this.hasLongTitle = titleHeight / elementHeight > 0.6;

    this.element.classList.toggle("has-title-aligned-to-bottom", this.hasLongTitle);
  }

  stickTitleToBottom() {
    this.element.classList.add("has-title-aligned-to-bottom");
  }

  onFooterFocus() {
    window.addEventListener("keydown", this.keydownHandler);
  }

  onFooterBlur() {
    window.removeEventListener("keydown", this.keydownHandler);
  }

  keydown(e) {
    switch (e.code) {
      case "Escape":
        this.footerTarget.blur();
        break;
    }
  }
}
