import { connectRefinementList } from "instantsearch.js/es/connectors";

const renderRefinementList = (renderOptions, isFirstRender) => {
  const {
    items,
    refine,
    widgetParams,
  } = renderOptions;

  const { container, templates, labelId } = widgetParams;
  let selectEl;

  if (isFirstRender) {
    const fragment = document.createRange().createContextualFragment(`
      <label class="ais-RefinementDropdown-label is-only-readable" for="${labelId}">
        ${widgetParams.title ? `${widgetParams.title}` : ''}
      </label>
      <select class="ais-RefinementDropdown-select" id="${labelId}">
      </select>
    `);
    container.appendChild(fragment);

    selectEl = container.querySelector(".ais-RefinementDropdown-select");

    selectEl.addEventListener('change', event => {
      event.preventDefault();
      const { value } = event.currentTarget;

      if (value == '') {
        // We are already refining with this facet: reset the select
        selectEl.selectedIndex = 0;
      } else {
        refine(value);
      }
    });
  }

  selectEl = container.querySelector(".ais-RefinementDropdown-select");

  selectEl.innerHTML = `
    ${templates.defaultOption ? `<option value="">${templates.defaultOption}</option>` : ''}
    ${items.map(
        item => `<option value="${item.isRefined ? '' : item.value}">${item.label} (${item.count})</option>`
      ).join('')}
  `;
};

export default connectRefinementList(renderRefinementList);
