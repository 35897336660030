import "./pool_affluences_component.scss";
import axios from "axios";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["blockToReplace"];

  connect() {
    this._fetchAttendance();
  }

  //PRIVATE

  _fetchAttendance() {
    axios({
      method: "GET",
      url: `/api/v1/pool_affluences?id=${this._poolId()}`,
    }).then((res) => {
      this.blockToReplaceTarget.innerHTML = res.data;
    });
  }

  _poolId() {
    const id = window.location.href.split("-").slice(-1);
    return id.toString();
  }
}
