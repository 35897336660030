import { Controller } from "stimulus";
import "./alert_component.scss";

export default class extends Controller {
  connect() {    
    const timeout = parseInt(this.data.get("timeout"), 10);
    if (timeout) {
      this.timeoutID = setTimeout(() => this.close(), timeout);
    }
  }

  close() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
      delete this.timeoutID;
    }

    this.element.classList.add("fade");
    setTimeout(() => this.remove(), 500);
  }

  remove() {
    this.element.parentNode.removeChild(this.element);
  }
}
