import application from "stimulus_application";

const context = require.context("../app/components", true, /[_\/]component\.js$/);

context.keys().forEach((path) => {
  const mod = context(path);

  // Check whether a module has the Controller export defined
  if (!mod.Controller) return;

  // Convert path into a controller identifier:
  //   example_component/example_component.js -> example
  //   nav/user_info_component/user_info_component.js -> nav--user-info
  const identifier = path.replace(/^\.\//, '')
    .replace(/_component\/.+_component\.js$/, '')
    .replace(/_/g, '-')
    .replace(/\//g, '--');

  application.register(identifier, mod.Controller);
});
