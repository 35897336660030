import "./banner_component.scss";

import { Controller as BaseController } from "stimulus";
import moment from "moment";
import "wicg-inert";
moment.locale("fr");

export class Controller extends BaseController {
  static targets = ["mainDiv"];

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
  }

  close() {
    this.setCookie("banner_closed_at", `${moment().format()}`, 365);
    this.element.inert = true;

    this.element.classList.add("slide-up");
    this.element.addEventListener(
      "animationend",
      () => {
        this.element.style.display = "none";
      },
      { once: true }
    );
  }
}
