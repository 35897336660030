import "./sidebar_component.scss";
import { decodeMails } from "../../../frontend/util/mailsHelper";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["textContainer"];

  connect() {
    decodeMails(this.hasTextContainerTarget, this.textContainerTarget, true);
  }
}
