import "./accessibility_settings_component.scss";
import { Controller as BaseController } from "stimulus";
import { setCookie, getCookie } from "../../assets/javascript/utils/cookie";

const COOKIE_LIFETIME = 365;

/**
 * Accessibility Settings
 * This controller allows a user to choose their theme and animation preferences
 * - First we detect if the browser has default values already set
 * - Then we read if there are cookies set
 * - - If no cookies are set we apply the browser values
 * - - Otherwise we apply the cookies
 * - Apply CSS classes to body
 */
export class Controller extends BaseController {
  // TODO: uncomment when dark mode is implemented
  // static targets = ["shortcutKey", "themeFormElement", "animationFormElement"];
  static targets = ["shortcutKey", "animationFormElement"];

  connect() {
    this.observeMenuState();
    this.setupShortcutKey();
    this.setupClickOutsideHandler();
    this.setupStoredAnimationPreference();
    // TODO: uncomment when dark mode is implemented
    // this.setupStoredThemePreference();
    this.updateBodyAttributes();
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside);
    this.disconnectObserver();
  }

  formSubmit(e) {
    e.preventDefault();
    this.updateBodyAttributes();
  }

  updateBodyAttributes() {
    // TODO: uncomment when dark mode is implemented
    // const theme = this.themeFormElementTarget.theme.value
    const animation = this.animationFormElementTarget.animation.value;

    document.body.setAttribute("data-animations", animation);
    // TODO: uncomment when dark mode is implemented
    // document.body.setAttribute('data-theme', theme)
  }

  checkRadioByValue(value, formElement) {
    const radioInput = formElement.querySelector(`input[value="${value}"]`);
    if (radioInput) {
      radioInput.checked = true;
    }
  }

  update(formElement, attributeName, cookieName) {
    if (!formElement) return;

    const selectedAttribute = formElement[attributeName];

    if (selectedAttribute) {
      setCookie(cookieName, selectedAttribute.value, COOKIE_LIFETIME);
      formElement.requestSubmit();
    }
  }

  // Menu
  closeMenu() {
    this.navLink.setAttribute("aria-expanded", "false");
    this.navLink.classList.remove("is-active");
    this.subNav.setAttribute("inert", "");
    this.navLink.click();
  }

  isActive(element, isActive) {
    if (isActive) {
      element.classList.add("is-active");
    } else {
      element.classList.remove("is-active");
    }
  }

  observeMenuState() {
    this.observer = new MutationObserver(() => {
      const isActive = this.navLink.getAttribute("aria-expanded") === "true";
      this.isActive(this.navLink, isActive);
    });

    this.observer.observe(this.navLink, { attributes: true, attributeFilter: ["aria-expanded"] });
  }

  disconnectObserver() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  setupShortcutKey() {
    if (this.hasShortcutKeyTarget) {
      this.shortcutKeyTarget.innerText = navigator.userAgent.includes("Mac") ? "Command ⌘" : "Ctrl";
    }
  }

  setupClickOutsideHandler() {
    document.addEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.navLink.getAttribute("aria-expanded") === "true") {
      if (!event.target.closest(".paris-header-nav-link") && !event.target.closest("header")) {
        this.closeMenu();
      }
    }
  };

  // Animation
  setupStoredAnimationPreference() {
    if (!this.animationFormElementTarget) return;

    const storedAnimationPreference = getCookie("animationPreference");
    const prefersReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
    const browserValue = prefersReduced ? "disabled" : "default";

    if (storedAnimationPreference) {
      this.checkRadioByValue(storedAnimationPreference, this.animationFormElementTarget);
    } else {
      this.checkRadioByValue(browserValue, this.animationFormElementTarget);
    }
  }

  updateAnimations() {
    this.update(this.animationFormElementTarget, "animation", "animationPreference");
  }

  // Theme
  // TODO: uncomment when dark mode is implemented
  // setupStoredThemePreference() {
  //   if (!this.themeFormElementTarget) return

  //   const storedThemePreference = getCookie("themePreference");
  //   // We could also check here for `prefers-color-scheme`, but by default it will return `light`.
  //   // It means there is no `default` value here, thus if we don't have cookies set,
  //   // the default value will be checked

  //   if (storedThemePreference) {
  //     this.checkRadioByValue(storedThemePreference, this.themeFormElementTarget);
  //   }
  // }

  // TODO: uncomment when dark mode is implemented
  // updateTheme() {
  //   this.update(this.themeFormElementTarget, "theme", "themePreference")
  // }

  get navItem() {
    return this.element.closest("li");
  }

  get navLink() {
    return this.navItem.querySelector(".paris-header-nav-link");
  }

  get subNav() {
    return this.navItem.querySelector(".paris-header-subnav");
  }
}
