import { setCookie } from "../../assets/javascript/utils/cookie";
import { pageType, humanized_status } from "../../assets/javascript/utils/helpers";

import "./cookies_component.scss";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["banner", "mainContainer", "articleTag"];

  connect() {
    // timeout the cookie check to wait for other controllers to be loaded
    setTimeout(() => {
      this._checkCookie();
      this._removeOldCookie();
    });
  }

  accept() {
    setCookie("cookies_compliance", "Accepted", 365);
    this._enableCookie();
    this._closeBanner();
    this._contentSquare();
    this._removeLocalStorageItem();
  }

  reject() {
    this._closeBanner();
    localStorage.setItem("cookies_compliance", false);
    this._uncheckboxes();
  }

  _checkCookie() {
    if (document.cookie.indexOf("cookies_compliance=Accepted") >= 0) {
      this._enableCookie();
      this._removeBanner();
      this._contentSquare();
    } else if (localStorage.getItem("cookies_compliance")) {
      this._removeBanner();
      this._uncheckboxes();
    } else {
      this._showCookiesBanner();
    }
  }

  _enableCookie() {
    this.element.dispatchEvent(new Event("cookies:accepted", { bubbles: true }));
  }

  _showCookiesBanner() {
    if (!this.hasBannerTarget) {
      return;
    }

    this.bannerTarget.classList.add("shown");
  }

  _closeBanner() {
    if (!this.hasBannerTarget) {
      return;
    }

    this.bannerTarget.addEventListener("transitionend", this._removeBanner.bind(this));
    this.bannerTarget.classList.remove("shown");
  }

  _removeBanner() {
    if (!this.hasBannerTarget) {
      return;
    }

    this.bannerTarget.removeEventListener("transitionend", this._removeBanner);
    this.bannerTarget.remove();
  }

  _contentSquare() {
    window._uxa = window._uxa || [];
    const pathname = window.location.pathname;
    const type = this.element.querySelector("#content").dataset.pageType;

    try {
      // Page Type
      window._uxa.push(["setCustomVariable", 1, "Page Type", pageType(type)]);

      // Page Format
      if (this.element.querySelector("#article")) {
        window._uxa.push([
          "setCustomVariable",
          2,
          "Page Format",
          this.element.querySelector("#article").dataset.pageFormat,
        ]);
      }

      // Page Status
      if ((type === "pages" || type === "themes" || type === "giants") && this.element.querySelector("#article")) {
        const status = this.element.querySelector("#article").dataset.pageStatus;
        window._uxa.push(["setCustomVariable", 3, "Page Status", humanized_status(status)]);
      }

      // Page Themes
      if ((type === "pages" || type === "giants" || type === "portfolios") && this.element.querySelector("#article")) {
        const themes = this.element.querySelector("#article").dataset.pageThemes;
        if (themes) window._uxa.push(["setCustomVariable", 4, "Page Themes", JSON.parse(themes).join(", ")]);
      }

      // Page Theme & children
      if (type === "themes") {
        const content = this.element.querySelector("#content");
        window._uxa.push([
          "setCustomVariable",
          5,
          "Theme",
          content.querySelector(".paris-container").dataset.pageTheme,
        ]);
        const parents = content.querySelector(".paris-container").dataset.pageAncestors;
        if (parents) {
          JSON.parse(parents)
            .slice(0, 5)
            .forEach((parent, index) => {
              window._uxa.push(["setCustomVariable", index + 5, `Parent Theme ${index + 1}`, parent]);
            });
        }
      }

      // QFAP
      const is_selections = type === "selections" && (pathname.includes("/week-end") || pathname.includes("/semaine"));
      const is_tags = type === "tags" && pathname.includes("/quefaire/");
      if (is_tags || is_selections) {
        window._uxa.push(["setCustomVariable", 5, "Theme", pathname.split("/").pop()]);
      }
    } catch (e) {
      console.error(e);
    }

    if (typeof CS_CONF === "undefined") {
      window._uxa.push(["setPath", window.location.pathname + window.location.hash.replace("#", "?__")]);
      const mt = document.createElement("script");
      mt.type = "text/javascript";
      mt.async = true;
      mt.src = "//t.contentsquare.net/uxa/d8d378de1b682.js";
      document.getElementsByTagName("head")[0].appendChild(mt);
    } else {
      window._uxa.push(["trackPageview", window.location.pathname + window.location.hash.replace("#", "?__")]);
    }
  }

  _uncheckboxes() {
    const checkboxes = document.querySelectorAll(".paris-cookies-checkbox input");
    [...checkboxes].forEach((checkbox) => (checkbox.checked = false));
  }

  _removeLocalStorageItem() {
    localStorage.removeItem("cookies_compliance");
  }

  _removeOldCookie() {
    document.cookie = "cookiesCompliance=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
}
