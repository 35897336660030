/* global gon */

import "./water_quality_component.scss";
import { Controller as BaseController } from "stimulus";
import moment from "moment";
import Chart from "chart.js";
import { options } from "./chart_options";

export class Controller extends BaseController {
  static targets = [
    "navLeft",
    "navRight",
    "day",
    "temperature",
    "rainfall",
    "flow",
    "icons",
    "ecoli",
    "enterococci",
    "tooltip",
  ];

  connect() {
    this.wqData = gon.water_quality;
    this.wqDataLength = this.wqData.length - 1;
    this.currentSlide = this.wqDataLength;

    this.datasets = {};

    this.maxEcoli = parseFloat(this.element.dataset.maxEColi);
    this.maxEnterococci = parseFloat(this.element.dataset.maxEnterococci);

    this._initEcoli();
    this._initEnterococci();
  }

  prev(e) {
    if (e.target.classList.contains("disabled")) return;

    if (this.currentSlide !== 0) {
      this.currentSlide--;

      this._toggleStatus();
    }
    this._updateWaterQualityData();
  }

  next(e) {
    if (e.target.classList.contains("disabled")) return;

    if (this.currentSlide !== this.wqDataLength) {
      this.currentSlide++;

      this._toggleStatus();
    }

    this._updateWaterQualityData();
  }

  _updateWaterQualityData() {
    this._updateData(this.wqData[this.currentSlide]);
    this._updateText();
    this._updateBacteriumData("ecoli");
    this._updateBacteriumData("enterocoques");
  }

  _toggleStatus() {    
    if (this.currentSlide === 0) {
      this.navLeftTarget.classList.add("disabled");
      
      if (this.wqData.length > 1) this.navRightTarget.classList.remove("disabled");
    } else {
      this.navLeftTarget.classList.remove("disabled");
      this.navRightTarget.classList.toggle("disabled", this.currentSlide === this.wqDataLength);
    }
  }

  _updateData(currentData) {
    const { days } = currentData;

    days.forEach((day, index) => {
      const formattedDay = moment(day.date, "DD/MM/YYYY");

      const dayNumber = formattedDay.format("DD");

      const dayName = formattedDay.format("dddd");
      const shortDayName = formattedDay.format("ddd");
      const shortDayDate = formattedDay.format("MMM");

      this.dayTargets[index].querySelectorAll(".blocks--wq-days-weekday").forEach((weekday) => {
        weekday.textContent = weekday.classList.contains("blocks--wq-desktop") ? dayName : shortDayName;
      });

      this.dayTargets[index].querySelector(".blocks--wq-days-day").textContent = dayNumber;

      this.dayTargets[index].querySelectorAll(".blocks--wq-days-month").forEach((month) => {
        month.textContent = shortDayDate;
      });

      const { temperature, weather, rainfall, flow } = day;
      const icon = this.iconsTarget.querySelector(`[data-name="${weather}"]`)?.innerHTML;

      const data = [
        {
          targets: this.temperatureTargets[index],
          selectorIcon: ".blocks--wq-icon",
          selector: ".blocks--wq-temp",
          value: temperature,
          valueIcon: icon,
        },
        {
          targets: this.rainfallTargets[index],
          selector: ".blocks--wq-rainfall span:nth-child(2)",
          value: rainfall,
        },
        {
          targets: this.flowTargets[index],
          selector: ".blocks--wq-flow span:nth-child(2)",
          value: flow,
        },
      ];

      this._updateElements(data);
    });
  }

  _updateElements(data) {
    data.forEach((item) => {
      const targets = item.targets;

      if (targets) {        
        if (item.selectorIcon) targets.querySelector(item.selectorIcon).innerHTML = item.valueIcon || "";

        const span = targets.querySelector("span");
        if (span) span.classList.toggle("hidden", !item.value);
        
        targets.querySelector(item.selector).textContent = item.value  || "";
      }
    });
  }

  _updateText() {
    const currentData = this.wqData[this.currentSlide];
    const { text, text_details } = currentData;    

    this.element.querySelector(".blocks--wq-text").innerHTML = text;
    this.element.querySelector(".blocks--wq-text-details").innerHTML = text_details;
  }

  _updateBacteriumData(bacterium) {
    this._setData(bacterium);

    const toUpdate = bacterium === "ecoli" ? this.chartEcoli : this.chartEnterococci;

    toUpdate.data.datasets.forEach((dataset, index) => {
      dataset.data = this.datasets[bacterium][index].data;
    });
    toUpdate.update();
  }

  _setData(bacterium) {
    const data = this.wqData[this.currentSlide][bacterium];
    const { bercy, marie, alexandre, grenelle } = data;

    const bercyColor = "#2EFFE6";
    const marieColor = "#0F776A";
    const alexandreColor = "#354BCF";
    const grenelleColor = "#37C3B2";

    const commonOptions = { 
      pointHoverRadius: 5,
      borderJoinStyle: "miter",
      pointBorderWidth: 2,
      pointHoverBorderColor: "rgba(255, 255, 255, 1)",
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 10,
    };

    this.datasets[bacterium] = [
      {
        label: "Bercy",
        data: bercy,
        borderColor: bercyColor,
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointBorderColor: bercyColor,
        pointBackgroundColor: bercyColor,
        pointHoverBackgroundColor: bercyColor,
        ...commonOptions
      },
      {
        label: "Bras Marie",
        data: marie,
        borderColor: marieColor,
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointBorderColor: marieColor,
        pointBackgroundColor: marieColor,
        pointHoverBackgroundColor: marieColor,
        ...commonOptions
      },
      {
        label: "Alexandre III",
        data: alexandre,
        borderColor: alexandreColor,
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointBorderColor: alexandreColor,
        pointBackgroundColor: alexandreColor,
        pointHoverBackgroundColor: alexandreColor,
        ...commonOptions
      },
      {
        label: "Bras de Grenelle",
        data: grenelle,
        borderColor: grenelleColor,
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointBorderColor: grenelleColor,
        pointBackgroundColor: grenelleColor,
        ...commonOptions
      },
    ];
  }

  _initEcoli() {
    this._setData("ecoli");
    this._drawChart("ecoli");
  }

  _initEnterococci() {
    this._setData("enterocoques");
    this._drawChart("enterocoques");
  }

  _drawChart(bacterium) {
    const days = this.wqData[this.currentSlide].days;
    const labels = days.map((day) => moment(day.date, "DD/MM/YYYY").format("dddd D MMMM"));
    const ctxCanvas = bacterium === "ecoli" ? this.ecoliTarget : this.enterococciTarget;
    const ctx = ctxCanvas.querySelector("canvas").getContext("2d");
    const max = bacterium === "ecoli" ? this.maxEcoli : this.maxEnterococci;    

    this._createChart(ctx, labels, bacterium, max);
  }

  _createChart(ctx, labels, bacterium, max) {
    const datasets = this.datasets[bacterium];
    const chartType = "line";
    const data = { labels, datasets };

    const plugins = [
      {
        beforeDraw: (chart) => {
          const render = document.querySelector("#blocks--wq-tooltip-tpl").dataset.tooltip;
          if (chart.tooltip._active && chart.tooltip._active.length && render !== "false") {
            const activePoint = chart.tooltip._active[0];
            const ctx = chart.ctx;
            const x = activePoint.tooltipPosition().x;

            const topY = chart.chartArea.top;
            const bottomY = chart.chartArea.bottom;

            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 4.45;
            ctx.strokeStyle = "#f2f6ff";
            ctx.stroke();
            ctx.restore();
          }
        },
        afterDraw: (chart) => {
          const ctx = chart.chart.ctx;
          const xAxis = chart.scales['x-axis-0'];
          const yAxis = chart.scales['y-axis-0'];
  
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(xAxis.left, yAxis.bottom);
          ctx.lineTo(xAxis.right, yAxis.bottom);
          ctx.lineWidth = 1; 
          ctx.strokeStyle = '#adb8ff'; 
          ctx.stroke();
          ctx.restore();
        }
      },
    ];

    options.scales.yAxes[0].ticks.suggestedMax = max;    

    if (bacterium === "ecoli") {
      this.chartEcoli = new Chart(ctx, { type: chartType, data, options, plugins });
    } else {
      this.chartEnterococci = new Chart(ctx, { type: chartType, data, options, plugins });
    }
  }

  _capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  download(e) {
    e.preventDefault();
    const link = e.currentTarget.closest('a');
    const csvData = link.dataset.csv;
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    const a = document.createElement('a');
    const url = URL.createObjectURL(blob);
    a.setAttribute('href', url);
    a.setAttribute('download', 'releves_qualite_seine.csv');
    a.click();
  }
}
