import { connectRefinementList } from "instantsearch.js/es/connectors";
import TomSelect from "tom-select";
import "tom-select/dist/scss/tom-select";

// Keep case to trick tomselect plugin import
// rip off https://github.com/orchidjs/tom-select/blob/master/src/plugins/remove_button/plugin.ts
function custom_remove_button() {
  var self = this;

  this.hook('after', 'setupTemplates', function() {
    var orig_render_item = self.settings.render.item;

    self.settings.render.item = (data, escape) => {
      let item = orig_render_item.call(self, data, escape);
      let closeButton = item.querySelector('.remove')
      closeButton.removeAttribute('tabindex')

      return item;
    };
  });
}

const renderRefinementList = (renderOptions, isFirstRender) => {
  const { items, refine, widgetParams } = renderOptions;
  const { container /*, templates*/ } = widgetParams;
  const renderOptionsItems = items;
  const currentRefinement = renderOptions.instantSearchInstance.helper.state.facetsRefinements["tags.name"];

  // FIRST RENDERING ------------------------------------------------
  if (isFirstRender) {
    const fragment = document.createRange().createContextualFragment(`
        <label class="ais-DateSelector-label-title" for="qfap-tag-selector">${widgetParams.title || "Tag(s)"}</label>
        <select id="qfap-tag-selector" multiple="multiple" class="form-multiple-input"></select>`);
    container.appendChild(fragment);
    return;
  }

  // OTHER RENDERINGS ------------------------------------------------
  const selectEl = document.querySelector("#qfap-tag-selector");
  if (selectEl.classList.contains("tomselected")) {
    window.tagSelector.clearOptions();
    window.tagSelector.addOptions(
      renderOptionsItems.map((renderOptionsItem) => {
        return {
          value: renderOptionsItem.value,
          text: renderOptionsItem.label,
        };
      })
    );
    window.tagSelector.settings.placeholder =
      items.length === 0 ? "Aucun tag disponible..." : "Choisir un ou des tag(s)...";
    window.tagSelector.inputState();
    return;
  }

  TomSelect.define('custom_remove_button', custom_remove_button)
  window.tagSelector = new TomSelect(selectEl, {
    plugins: {
      input_autogrow: {},
      remove_button: { title: "Retirer" },
      custom_remove_button: { },
    },
    placeholder: items.length === 0 ? "Aucun tag disponible..." : "Choisir un ou des tag(s)...",
    items: currentRefinement,
    options: renderOptionsItems.map((renderOptionsItem) => {
      return {
        value: renderOptionsItem.value,
        text: renderOptionsItem.label,
      };
    }),
    render:{
      no_results: function(data,escape){
        return '<div class="no-results">Aucun tag trouvé pour "'+escape(data.input)+'"</div>';
      },
    },
    closeAfterSelect: true,
    hidePlaceholder: true,
  });

  window.tagSelector.on("item_add", (value) => refine(value.split(",").pop()));
  window.tagSelector.on("item_remove", (value) => refine(value.split(",").pop()));
};

export default connectRefinementList(renderRefinementList);
