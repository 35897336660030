/* global L */

L.Control.Calendar = L.Control.extend({
  options: {
    id: 1,
    position: "bottomright",
  },
  initialize: function (options) {
    L.setOptions(this, options);
  },
  onAdd: function (_map) {
    this.container = L.DomUtil.create("div", "date-control-container");
    this.inputDate = L.DomUtil.create("div", "date-control", this.container);

    const calendarMarkup = `<div class="current-date ${
      !window.matchMedia("(min-width:640px)").matches ? "" : "open"
    }"><span>${
      this._date
        ? this._date.toLocaleDateString("fr", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        : ""
    }</span></div><div class="calendar-container ${!window.matchMedia("(min-width:640px)").matches ? "" : "active"}">
        <header class="calendar-header">
          <div class="tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="juillet" value="6">
            <label for="tab1">Juillet</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="aout" value="7">
            <label for="tab2">Août</label>
            <!-- Tab 3 -->
            <input type="radio" name="tabset" id="tab3" aria-controls="septembre" value="8">
            <label for="tab3">Septembre</label>
          </div>
        </header>
        <div class="calendar-body">
            <ul class="calendar-weekdays">
                <li>Lu</li>
                <li>Ma</li>
                <li>Me</li>
                <li>Je</li>
                <li>Ve</li>
                <li>Sa</li>
                <li>Di</li>
            </ul>
            <ul class="calendar-dates"></ul>
        </div>
    </div><div class="legend">
            <span class="flamme">Parcours de la flamme olympique</span>
            <span class="para">Parcours de la flamme paralympique</span>
            <span class="course">Courses sur route</span>
        </div>`;

    this.inputDate.innerHTML = calendarMarkup;

    const calendarContainer = this.container.querySelector(".calendar-container");

    const calendarDate = this.container.querySelector(".current-date");

    calendarDate.addEventListener("click", () => {
      calendarDate.classList.toggle("open");
      /** Slide down. */
      if (!calendarContainer.classList.contains("active")) {
        /** Show the container. */
        calendarContainer.classList.add("active");
        calendarContainer.style.height = "auto";

        /** Get the computed height of the container. */
        var height = calendarContainer.clientHeight + "px";

        /** Set the height of the content as 0px, */
        /** so we can trigger the slide down animation. */
        calendarContainer.style.height = "0px";

        /** Do this after the 0px has applied. */
        /** It's like a delay or something. MAGIC! */
        setTimeout(() => {
          calendarContainer.style.height = height;
        }, 0);

        /** Slide up. */
      } else {
        /** Set the height as 0px to trigger the slide up animation. */
        calendarContainer.style.height = "0px";

        /** Remove the `active` class when the animation ends. */
        calendarContainer.addEventListener(
          "transitionend",
          () => {
            calendarContainer.classList.remove("active");
          },
          { once: true }
        );
      }
    });

    this._addJOCalendar();
    return this.container;
  },
  _addJOCalendar() {
    this._date = new Date(); // creates a new date object with the current date and time
    this._year = this._date.getFullYear(); // gets the current year
    this._month = this._date.getMonth(); // gets the current month (index based, 0-11)

    this._day = this.container.querySelector(".calendar-dates"); // selects the element with class "calendar-dates"

    const currentTab = this.container.querySelector(`.calendar-header input[name="tabset"][value="${this._month}"]`);
    if (currentTab) {
      currentTab.checked = true;
    }

    // function to generate the calendar
    this._manipulate();
  },
  _manipulate() {
    // get the first day of the month
    let dayone = new Date(this._year, this._month, 1).getDay();

    // get the last date of the month
    let lastdate = new Date(this._year, this._month + 1, 0).getDate();

    // get the day of the last date of the month
    let dayend = new Date(this._year, this._month, lastdate).getDay();

    // get the last date of the previous month
    let monthlastdate = new Date(this._year, this._month, 0).getDate();

    let lit = ""; // variable to store the generated calendar HTML

    // loop to add the last dates of the previous month
    if (dayone === 0) dayone = 7;
    if (dayend === 0) dayend = 7;

    let totalDays = 0;

    for (let i = dayone; i > 1; i--) {
      lit += `<li class="inactive">${monthlastdate - i + 2}</li>`;
      totalDays++;
    }

    // loop to add the dates of the current month
    for (let i = 1; i <= lastdate; i++) {
      // check if the current date is today
      let isToday =
        i === this._date.getDate() && this._month === new Date().getMonth() && this._year === new Date().getFullYear()
          ? "active today"
          : "";
      lit += `<li class="${isToday}" data-date="${new Date(this._year, this._month, i).toLocaleDateString(
        "fr"
      )}">${i}<div class="dots"></div></li>`;
      totalDays++;
    }

    // loop to add the first dates of the next month
    for (let i = dayend; i < 7; i++) {
      lit += `<li class="inactive">${i - dayend + 1}</li>`;
      totalDays++;
    }

    if (totalDays <= 35) {
      for (let i = 7 - dayend; i < 7 - dayend + 7; i++) {
        lit += `<li class="inactive">${i + 1}</li>`;
      }
    }

    // update the HTML of the dates element with the generated calendar
    this._day.innerHTML = lit;
  },
});

L.control.calendar = function (options) {
  return new L.Control.Calendar(options);
};
