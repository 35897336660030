import "./alert_component.scss";
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  close() {     
    this.element.classList.add('slide-up');
    this.element.addEventListener('animationend', () => {
      this.element.style.display = 'none';
    }, { once: true });
  }
}
