/* global gon */

export default (key, aria_label) => {
  const template = aria_label ? gon.icons.template_with_aria_label : gon.icons.template;
  let output = template.replace(/{{icon}}/g, key.replace("/", "--"));

  if (aria_label) {
    output = output.replace(/{{aria_label}}/g, aria_label);
  }

  return output;
};