import "./compatibility_alerts_component.scss";
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = [];

  connect() {
    this.isIE = /Trident\/|MSIE/.test(window.navigator.userAgent);
    if (this.isIE) this.element.classList.remove("d-none");
  }
}
