import "./collapsable_component.scss";
import Accordion from "@accede-web/accordion";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["content", "chevron"];

  connect() {
    this.accordion = new Accordion(this.element);
    this.accordion.mount();
  }

  toggleCollapsable() {
    const actualState = this.contentTarget.getAttribute('aria-hidden') === "true";
    this.contentTarget.setAttribute('aria-hidden', !actualState);
    this.chevronTarget.classList.toggle("closed", !actualState);
  }
}
