import "./gallery_component.scss";

import Flickity from "flickity";
import "flickity-imagesloaded";
import "flickity/dist/flickity.css";
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["images", "previous", "next"];

  initialize() {
    this.flkty = null;
    this.timeout = false;
    this.delay = 200;
    this.images = null;
  }

  connect() {
    this.handleFlickity();
    window.addEventListener("resize", () => this.handleResize());
  }

  handleResize() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.handleFlickity(), this.delay);
  }

  handleFlickity() {
    if (this.mainContainer && !this.isInGiantContainer) {
      this.element.style.width = this.mainContainer.offsetWidth + "px";
    }

    if (this.flkty) {
      this.flkty.destroy();
    }

    // init / reset flickity
    this.flkty = new Flickity(this.imagesTarget, {
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true,
      accessibility: true,
      lazyLoad: 2,
      cellAlign: this.hasSidebar ? "left" : "center",
      on: {
        ready: () => this.resizeGallery(),
        scroll: (progress) => this.handleScroll(progress),
      },
    });

    // add btn events
    this.previousTarget.addEventListener("click", () => {
      this.flkty.previous();
    });

    this.nextTarget.addEventListener("click", () => {
      this.flkty.next();
    });
  }

  resizeGallery() {
    const containerComponentsOffsetLeft = this.componentsContainer ? this.componentsContainer.offsetLeft : 0;
    const sliderTranslate = this.getTranslate(this.imagesTarget.querySelector(".flickity-slider"))[0];
    const imageMarginLeft = Math.ceil(containerComponentsOffsetLeft - sliderTranslate - 70);

    if (!this.isInDesktopGiantContainer && !this.hasSidebar) {
      this.imagesTarget.querySelectorAll(".image-footer").forEach((img) => {
        img.style.marginLeft = this.isSmallAndMedium ? "3rem" : `${imageMarginLeft}px`;
      });
    }

    if (this.isInDesktopGiantContainer) {
      this.element.querySelector(".flickity-slider").style.left = "-80px";
    }

    if (this.hasSidebar && !this.isSmallAndMedium) {
      this.element.querySelector(".flickity-slider").style.left = "33vw";
    }
  }

  handleScroll(progress) {
    const p = Math.round(progress * 10) / 10;
    const hasPrevious = p > 0;
    const hasNext = p < 1;

    this.previousTarget.classList.toggle("is-disabled", !hasPrevious);

    if (hasPrevious) {
      this.previousTarget.removeAttribute("tabindex");
    } else {
      this.previousTarget.setAttribute("tabindex", "-1");
    }

    this.nextTarget.classList.toggle("is-disabled", !hasNext);

    if (hasNext) {
      this.nextTarget.removeAttribute("tabindex");
    } else {
      this.nextTarget.setAttribute("tabindex", "-1");
    }
  }

  getTranslate(item) {
    const transArr = [];
    if (!window.getComputedStyle) return;

    const style = getComputedStyle(item);
    const transform = style.transform || style.webkitTransform || style.mozTransform || style.msTransform;

    var mat = transform.match(/^matrix3d\((.+)\)$/);
    if (mat) return parseFloat(mat[1].split(", ")[13]);

    mat = transform.match(/^matrix\((.+)\)$/);
    mat ? transArr.push(parseFloat(mat[1].split(", ")[4])) : transArr.push(0);
    mat ? transArr.push(parseFloat(mat[1].split(", ")[5])) : transArr.push(0);

    return transArr;
  }

  get componentsContainer() {
    return document.querySelector(".paris-container-components");
  }

  get mainContainer() {
    return document.querySelector("main");
  }

  get isSmallAndMedium() {
    return window.matchMedia("(max-width:800px)").matches;
  }

  get isInDesktopGiantContainer() {
    return Boolean(document.querySelector(".giant-container")) && window.matchMedia("(min-width:750px)").matches;
  }

  get isInGiantContainer() {
    return Boolean(document.querySelector(".giant-container"));
  }

  get hasSidebar() {
    return Boolean(document.querySelector(".paris-container.has-sidebar"));
  }
}
