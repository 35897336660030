import { getCookie } from "./cookie";

export const hasDisabledAnimations = () => {
  const storedAnimationPreference = getCookie("animationPreference");
  const prefersReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
  const browserValue = prefersReduced ? "disabled" : "default";
  const disableAnimations = browserValue === "disabled" || storedAnimationPreference === "disabled";

  return disableAnimations;
};
