import "./expandable_component.scss";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["link", "content"];

  connect() {
    this._isExpanded = this.linkTarget.getAttribute("aria-expanded") === "true";
  }

  toggle(e) {
    e.preventDefault();

    if (this._isExpanded) {
      this._isExpanded = false;
      this.linkTarget.setAttribute("aria-expanded", "false");
    } else {
      this._isExpanded = true;
      this.linkTarget.setAttribute("aria-expanded", "true");
    }
    let showText = e.target.dataset.show || 'Afficher';
    let hideText = e.target.dataset.hide || 'Masquer';

    e.target.innerText = this._isExpanded ? hideText : showText;
  }
}
