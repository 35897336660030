import { Controller as BaseController } from "stimulus";
import "./footer_component.scss";

export class Controller extends BaseController {
  static targets = ["mairies"];

  onChange(e) {
    const select = e.target;
    let url = select.options[select.selectedIndex].value;

    if (url !== "") {
      window.location.href = url;
    }
  }
}
