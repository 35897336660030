/* global gon */

import _get from "lodash/get";

export const mairieIdTransformItems = (items) => {
  return items.map((item) => {
    let label = `${item.label}e arrondissement`;
    if (item.label === "1234") label = "Paris Centre";
    if (item.label === "other") label = "Hors Paris";

    return {
      ...item,
      label: label,
    };
  });
};

export const districtNameSortBy = (firstItem = "1234", lastItem = "other") => {
  return (a, b) => {
    // special case for Paris Centre => first
    // special case for Hors Paris => last

    if (a.name === firstItem) {
      a = -1;
    } else if (a.name === lastItem) {
      a = 9999;
    } else {
      a = parseInt(a.name, 10);
    }

    if (b.name === firstItem) {
      b = -1;
    } else if (b.name === lastItem) {
      b = 9999;
    } else {
      b = parseInt(b.name, 10);
    }

    return a - b;
  };
};

export const linkForMairie = (item, pathAttribute) => {
  const currentMairie = gon.mairie;
  const path = item[pathAttribute];

  if (currentMairie.id === null || item.mairie_id === currentMairie.id) {
    return {
      href: path,
      target: "",
    };
  }

  return {
    href: `${window.location.protocol}//${gon.mairie.global_host}${path}`,
    target: "_target",
  };
};

export const linkForGlobal = (item, pathAttribute) => {
  const path = item[pathAttribute];

  return {
    href: `${window.location.protocol}//${gon.mairie.global_host}${path}`,
    target: "_target",
  };
};

export const localize = (path, defaultValue = "") => {
  return _get(gon, "search.locales." + path, defaultValue);
};
