import "./alerts_component.scss";
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["errorMessage"];

  open(e) {
    e.preventDefault();
    this.element.classList.add("is-open");
    this.emailInput?.focus();
  }

  confirm() {
    this.element.classList.add("is-confirmed");
  }

  get emailInput() {
    return this.element.querySelector('input[type="email"]');
  }
}
